import { API_PATH } from "@/constants/config";
import { request } from "@/helper/http";
import { DictionaryFile } from "@/types/dictionary";
import { downloadFromBinary, getFileNameFromDisposition } from "@cinnamon/design-system";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export const downloadDictionary = async () => {
  const result = await request({
    url: `${API_PATH.DICTIONARIES}download/`,
    method: "get",
    responseType: "blob",
    shouldGetFullResponse: true,
  });

  const fileName = getFileNameFromDisposition(result.headers["content-disposition"]);
  downloadFromBinary(result.data, fileName);
};

export const dictionaryApi = createApi({
  reducerPath: "dictionaryApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Dictionary"],
  endpoints: (builder) => ({
    getDictionary: builder.query({
      queryFn: async () => {
        try {
          const data: DictionaryFile = await request({
            url: API_PATH.DICTIONARIES,
            method: "get",
          });

          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Dictionary"],
    }),
  }),
});

export const { useGetDictionaryQuery } = dictionaryApi;
