import { AzureLoginPayload, UpdatedUserInput, User } from "@/types/user";
import { REFRESH_TOKEN_STORAGE_NAME, TOKEN_STORAGE_NAME } from "@cinnamon/design-system";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { azureLogin, getUserInfo, updateUserInfo } from "@services/auth";
import { getAllUsers } from "@services/user";
import jwt from "jwt-decode";

export interface UserState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: User;
  scope: string;
  users: User[];
}

export type SessionInfo = Pick<UserState, "user" | "scope">;
export type UserInfo = Pick<UserState, "user">;

const initialState: UserState = {
  isInitialized: false,
  isAuthenticated: false,
  user: undefined,
  scope: "",
  users: [],
};

export const getSessionInfo = createAsyncThunk("user/getSessionInfo", async (_, thunkApi) => {
  try {
    const accessToken = `${localStorage.getItem(TOKEN_STORAGE_NAME)}`;
    const token = accessToken.split("Bearer ").at(1);
    let scope = "";
    if (token) {
      scope = jwt<{ scope: string }>(token).scope || "";
      // console.log("🚀 ~ file: userSlice.ts:31 ~ getSessionInfo ~ scope:", scope);
      const user = await getUserInfo(accessToken);
      const sessionInfo = { user, scope } as SessionInfo;
      if (sessionInfo) {
        thunkApi.dispatch(updateSession(sessionInfo));
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    thunkApi.dispatch(initSession()); // turn off loader and init page
  }
});

export const loginAsyncThunk = createAsyncThunk(
  "user/loginAsyncThunk",
  async (payload: AzureLoginPayload, { rejectWithValue }) => {
    try {
      const result = await azureLogin({ accessToken: payload.accessToken });

      const token = result.access;
      const accessToken = `${result.token_type || "Bearer"} ${token}`;
      const refreshToken = result.refresh;
      let scope = "";
      if (token) {
        scope = jwt<{ scope: string }>(token).scope || "";
      }
      localStorage.setItem(TOKEN_STORAGE_NAME, accessToken);
      localStorage.setItem(REFRESH_TOKEN_STORAGE_NAME, refreshToken);

      const user = await getUserInfo(accessToken);
      return { user, scope };
    } catch (err) {
      return rejectWithValue(JSON.stringify(err));
    }
  }
);

export const getUsers = createAsyncThunk("user/getUsers", async () => {
  const users = await getAllUsers();
  return { users };
});

export const updateUserInfoAsyncThunk = createAsyncThunk(
  "user/updateUserInfoAsyncThunk",
  async (userInfo: UpdatedUserInput, { rejectWithValue }) => {
    try {
      const user = await updateUserInfo(userInfo);
      return { user };
    } catch (err) {
      return rejectWithValue(JSON.stringify(err));
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initSession: (state) => {
      state.isInitialized = true;
    },
    updateSession: (state, action) => {
      const { payload } = action as PayloadAction<SessionInfo>;
      state.user = payload.user;
      state.isAuthenticated = true;
      state.scope = payload.scope;
    },
    clearSession: (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
      state.scope = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsyncThunk.fulfilled, (state, action: PayloadAction<SessionInfo>) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.scope = action.payload.scope;
    });
    builder.addCase(loginAsyncThunk.rejected, () => {
      localStorage.removeItem(TOKEN_STORAGE_NAME);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_NAME);
    });
    builder.addCase(updateUserInfoAsyncThunk.fulfilled, (state, action: PayloadAction<UserInfo>) => {
      state.user = action.payload?.user;
    });
    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<{ users: User[] }>) => {
      state.users = action.payload.users;
    });
  },
});

export const { updateSession, clearSession, initSession } = userSlice.actions;

export default userSlice.reducer;
