import { MODEL_TYPE } from "@/types/file";
import { Workflow } from "@/types/workflow";

export const workflowToModelType = (workflow: Workflow) => {
  switch (workflow.code_name) {
    case MODEL_TYPE.DELIVERY_FORM:
      return MODEL_TYPE.DELIVERY_FORM;
    case MODEL_TYPE.ORDER_FORM:
      return MODEL_TYPE.ORDER_FORM;
    default:
      return MODEL_TYPE.OTHERS;
  }
};
