import { jobApi } from "@/services/file";
import { llmSampleApi } from "@/services/llmSample";
import { pipelineApi } from "@/services/pipeline";
import { templateApi } from "@/services/template";
import { userApi } from "@/services/user";
import { workflowApi } from "@/services/workflow";
import { combineReducers } from "@reduxjs/toolkit";

import { departmentApi } from "@/services/department";
import { dictionaryApi } from "@/services/dictionary";
import { statisticApi } from "@/services/statistic";
import featureFlagReducer from "@redux/reducer/featureFlagSlice";
import userReducer from "@redux/reducer/userSlice";
import workflowReducer from "@redux/reducer/workflowSlice";

export const rootReducer = combineReducers({
  user: userReducer,
  workflow: workflowReducer,
  featureFlag: featureFlagReducer,
  [workflowApi.reducerPath]: workflowApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [jobApi.reducerPath]: jobApi.reducer,
  [pipelineApi.reducerPath]: pipelineApi.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  [llmSampleApi.reducerPath]: llmSampleApi.reducer,
  [statisticApi.reducerPath]: statisticApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [departmentApi.reducerPath]: departmentApi.reducer,
});
