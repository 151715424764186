import { ChangePassInput, CreatedUserInput, SystemSettings, UpdatedUserInput, User, UserId } from "@/types/user";

import { APIQuery, APIResponse } from "@/types/global";
import { API_PATH } from "@constants/config";
import { request } from "@helper/http";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export function createUser(data: CreatedUserInput) {
  return request({
    url: API_PATH.USERS,
    method: "post",
    data,
  });
}

export function deleteUser(id: UserId) {
  return request({
    url: `${API_PATH.USERS}${id}/`,
    method: "delete",
  });
}

export function bulkDeleteUser(ids: string) {
  return request({
    url: `${API_PATH.BULK_DELETE_USER}`,
    method: "delete",
    params: { ids },
  });
}

export function updateUser(id: UserId, data: UpdatedUserInput) {
  return request({
    url: `${API_PATH.USERS}${id}/`,
    method: "patch",
    data,
  });
}

export async function checkEmailUser() {
  //email: string
  return true;
  // return request({
  //   method: "get",
  //   url: API_PATH.CHECK_EMAIL,
  //   params:  {
  //     email
  //   }
  // });
}

export const getUsers = async (params: APIQuery) => {
  const response: APIResponse<User> = await request({ url: API_PATH.USERS, method: "get", params });

  const normalizedUsers = response.results.map((item, i) => {
    return { ...item, number: i + 1 + (params?.offset ?? 0) };
  });
  return { ...response, results: normalizedUsers };
};

export const getAllUsers = async (noNeedPaging?: boolean) => {
  let limit = 300;
  let users: User[] = [];

  let response = await getUsers({ limit, offset: 0 });

  if (response) {
    users = response.results;
    if (noNeedPaging) {
      limit = response.count - users.length;
    }
    while (users.length < response.count) {
      const offset = users.length;
      const params: APIQuery = { limit, offset };
      response = await getUsers(params);
      users.push(...response.results);
    }
  }

  return users || [];
};

export const updatePassword = (id: string, data: ChangePassInput) => {
  return request({
    method: "patch",
    data,
    url: `${API_PATH.USERS}${id}/`,
  });
};

// @ts-expect-error only use 2nd input
export const updateCurrentUserPassword = (id: UserId, data: ChangePassInput) => {
  return request({
    method: "patch",
    data,
    url: `${API_PATH.USER_INFO}change-password/`,
  });
};

export const getSystemSettings = () => {
  return request({
    method: "get",
    url: API_PATH.SYSTEM_SETTINGS,
  });
};

export const updateSystemSettings = (data: SystemSettings) => {
  return request({
    method: "patch",
    data,
    url: API_PATH.SYSTEM_SETTINGS,
  });
};

export interface UserAPIQuery extends APIQuery {
  email__icontains?: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.query({
      queryFn: async (params: UserAPIQuery) => {
        try {
          const data = await getUsers(params);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Users"],
    }),
  }),
});

export const { useLazyGetUsersQuery } = userApi;
