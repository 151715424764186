import { SelectOption } from "@cinnamon/design-system";

export type FixFormDataEnhancementId = string;

export enum STEP_TYPE_DATA_ENHANCEMENT {
  FIND_AND_REPLACE = "find-and-replace",
  TRIM_SPACES = "trim-spaces",
  FORMAT_TEXT = "format-text",
}

export enum FORMAT_TEXT_TYPE_DATA_ENHANCEMENT {
  LITERAL = "literal",
  PLACEHOLDER = "placeholder",
}

export interface IFieldInfo {
  id: string;
  display_name: string;
}

export type FixFormDataEnhancementValue = string | SelectOption;

export interface IFixFormDataEnhancement {
  id: FixFormDataEnhancementId;
  action: STEP_TYPE_DATA_ENHANCEMENT | null;
  input: IFieldInfo[];
  output: IFieldInfo[];
  description: string;
  value: FixFormDataEnhancementValue[];

  canExpand?: boolean;
}
export interface ITemplateDataEnhancement {
  type: FORMAT_TEXT_TYPE_DATA_ENHANCEMENT;
  value: string;
}
export type FixFormDataEnhancementServer = Partial<Omit<IFixFormDataEnhancement, "input" | "output">> & {
  template?: ITemplateDataEnhancement[];
  find?: string;
  replace?: string;
  remove_all?: boolean;
  input: string[];
  output: string;
};
