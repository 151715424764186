import { useAppSelector } from "@/redux/hook";
import { featureFlagState } from "@/redux/store";
import { FLAG_TICKET } from "@/types/featureFlag";
import { useMemo } from "react";

export const useIsEnableFeatureFlag = (ticket: FLAG_TICKET) => {
  const { entities } = useAppSelector(featureFlagState);

  return useMemo(() => {
    const flag = entities?.find((entity) => entity.ticket === ticket);

    // If flag does not exist, default to true
    if (!flag) {
      return true;
    }

    return flag.enable;
  }, [entities, ticket]);
};
