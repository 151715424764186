import { API_PATH } from "@/constants/config";
import { request } from "@/helper/http";
import { LLMSample, LLMSampleId, LLMSampleKVConfig } from "@/types/llmSample";
import { RequestOptions } from "@cinnamon/design-system";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export interface CreateLLMSamplePayload {
  pipeline_version: string;
  display_name: string;
}
export const createLLMSample = async (payload: CreateLLMSamplePayload) => {
  const response = await request({ url: API_PATH.LLM_SAMPLES, method: "post", data: payload });
  return response;
};

export const getLLMSample = async (id: LLMSampleId) => {
  const response = await request({ url: `${API_PATH.LLM_SAMPLES}${id}/`, method: "get" });
  return response;
};

export const deleteLLMSample = async (id: LLMSampleId) => {
  const response = await request({ url: `${API_PATH.LLM_SAMPLES}${id}/`, method: "delete" });
  return response;
};

export interface UpdateLLMSamplePayload {
  sampleId: LLMSampleId;
  data: Partial<LLMSample>;
}

export const updateLLMSample = (payload: UpdateLLMSamplePayload) => {
  const { sampleId, data } = payload;

  return request({
    url: `${API_PATH.LLM_SAMPLES}${sampleId}/`,
    method: "patch",
    data,
  });
};

export interface ImagesResponse {
  result: string[];
}

export const getLLMSampleImages = (sampleId: LLMSampleId): Promise<ImagesResponse> => {
  return request({
    url: `${API_PATH.LLM_SAMPLES}${sampleId}/images/`,
    method: "get",
    params: {
      timestamp: new Date().getTime(),
    },
  });
};

export const getLLMSampleThumbnails = (sampleId: LLMSampleId): Promise<ImagesResponse> => {
  return request({
    url: `${API_PATH.LLM_SAMPLES}${sampleId}/thumbnails/`,
    method: "get",
    params: {
      timestamp: new Date().getTime(),
    },
  });
};

export interface UploadLLMSampleFilePayload {
  sampleId: LLMSampleId;
  file: File;
  config?: RequestOptions;
}

export const uploadLLMSampleFile = (payload: UploadLLMSampleFilePayload) => {
  const { sampleId, file, config } = payload;

  const formData = new FormData();
  formData.append("file", file);

  return request({
    url: `${API_PATH.LLM_SAMPLES}${sampleId}/original-file/`,
    method: "post",
    data: formData,
    ...config,
  });
};

export interface UpdateLLMSampleConfigsPayload {
  sampleId: LLMSampleId;
  kvConfigs: LLMSampleKVConfig;
}

export const updateLLMSampleConfigs = (payload: UpdateLLMSampleConfigsPayload): Promise<LLMSample> => {
  const { sampleId, kvConfigs } = payload;

  return request({
    url: `${API_PATH.LLM_SAMPLES}${sampleId}/kv-configs/`,
    method: "put",
    data: { kv_configs: kvConfigs },
  });
};

export const llmSampleApi = createApi({
  reducerPath: "llmSampleApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["LLMSample"],
  endpoints: (builder) => ({
    getLLMSample: builder.query({
      queryFn: async (id: LLMSampleId) => {
        try {
          const data = await getLLMSample(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["LLMSample"],
    }),
    createLLMSample: builder.mutation({
      queryFn: async (payload: CreateLLMSamplePayload) => {
        try {
          const data = await createLLMSample(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["LLMSample"],
    }),
    deleteLLMSample: builder.mutation({
      queryFn: async (id: LLMSampleId) => {
        try {
          const data = await deleteLLMSample(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["LLMSample"],
    }),
    updateLLMSample: builder.mutation({
      queryFn: async (payload: UpdateLLMSamplePayload) => {
        try {
          const data = await updateLLMSample(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["LLMSample"],
    }),
    getLLMSampleImages: builder.query({
      queryFn: async (sampleId: LLMSampleId) => {
        try {
          const data = await getLLMSampleImages(sampleId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["LLMSample"],
    }),
    getLLMSampleThumbnails: builder.query({
      queryFn: async (sampleId: LLMSampleId) => {
        try {
          const data = await getLLMSampleThumbnails(sampleId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["LLMSample"],
    }),
    uploadLLMSampleFile: builder.mutation({
      queryFn: async (payload: UploadLLMSampleFilePayload) => {
        try {
          const data = await uploadLLMSampleFile(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["LLMSample"],
    }),
    updateLLMSampleConfigs: builder.mutation({
      queryFn: async (payload: UpdateLLMSampleConfigsPayload) => {
        try {
          const data = await updateLLMSampleConfigs(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["LLMSample"],
    }),
  }),
});

export const {
  useGetLLMSampleQuery,
  useCreateLLMSampleMutation,
  useDeleteLLMSampleMutation,
  useUpdateLLMSampleMutation,
  useUploadLLMSampleFileMutation,
  useUpdateLLMSampleConfigsMutation,
} = llmSampleApi;
