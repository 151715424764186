import { APIQuery, APIResponse, WorkflowAPIQuery } from "@/types/global";
import { API_PATH } from "@constants/config";
import { request } from "@helper/http";

import { Workflow } from "@/types/workflow";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export async function getWorkflows(params?: APIQuery) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/`,
    method: "get",
    params: {
      limit: params?.limit,
      offset: params?.offset,
      search: params?.search,
      ordering: params?.ordering,
    },
  });
}

export const getAllWorkflows = async (limit = 30) => {
  let workflows: Workflow[] = [];

  // function will loop to get all workflows
  const getWorkflowsRecursive = async (offset: number, hasMore: boolean) => {
    if (!hasMore) {
      return;
    }
    const params: APIQuery = { limit: limit, offset };
    const response = await getWorkflows(params);
    hasMore = response.results.length + workflows.length < response.count;
    offset += limit;
    workflows = [...workflows, ...response.results];
    await getWorkflowsRecursive(offset, hasMore);
  };

  // execute to get all team
  await getWorkflowsRecursive(0, true);
  workflows?.sort((a, b) => a.display_name.localeCompare(b.display_name));
  return workflows ?? [];
};

export async function getWorkflowByCodeName(codeName: string) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/?code_name=${codeName}`,
    method: "get",
  });
}

export const getWorkflowByID = (id: string): Promise<Workflow> => {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/${id}/`,
    method: "get",
  });
};

export async function createWorkflow(payload?: any) {
  return request({
    url: `${API_PATH.WORKFLOWS_LIST}/`,
    method: "post",
    data: payload,
  });
}

export async function deleteWorkflow(id: string) {
  return request({
    url: API_PATH.WORKFLOW(id),
    method: "delete",
  });
}

export enum UPDATE_WORKFLOW_PART {
  DETAIL = "detail",
  EXECUTOR = "executor",
  PERMISSION = "permission",
}

export interface UpdateWorkflowRequest {
  id: string;
  part: UPDATE_WORKFLOW_PART;
  payload: WorkflowAPIQuery;
}

export async function updateWorkflow({ id, part, payload }: UpdateWorkflowRequest) {
  return request({
    url: API_PATH.WORKFLOW(`${id}/${part}`),
    method: "patch",
    data: { ...payload },
  });
}

export async function updateWorkflowData(workflowId: string, payload: any) {
  return request({
    url: API_PATH.WORKFLOW(`${workflowId}`),
    method: "patch",
    data: { ...payload },
  });
}

export const workflowApi = createApi({
  reducerPath: "workflowApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Workflows"],
  keepUnusedDataFor: 1,
  endpoints: (build) => ({
    getWorkflows: build.query({
      queryFn: async (params: APIQuery) => {
        try {
          const data: APIResponse<Workflow> = await request({
            url: `${API_PATH.WORKFLOWS_LIST}/`,
            method: "GET",
            params,
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Workflows"],
    }),
    invalidateWorkflows: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ["Workflows"],
    }),
  }),
});

export const { useLazyGetWorkflowsQuery, useGetWorkflowsQuery, useInvalidateWorkflowsMutation } = workflowApi;
