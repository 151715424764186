import { Workflow } from "@/types/workflow";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const WORKFLOW_LIMIT = 20;

export interface WorkflowState {
  workflows: Workflow[];
  offset: number;
  total: number | null;
  shouldRefetch: boolean;
}

const initialState: WorkflowState = {
  workflows: [],
  offset: 0,
  total: null,
  shouldRefetch: false,
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    updateWorkflowState: (state, action: PayloadAction<{ workflows: Workflow[]; total: number }>) => {
      const { payload } = action;
      state.workflows = payload.workflows;
      state.total = payload.total;
      state.shouldRefetch = false;
    },
    updateWorkflowOffset: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.offset = payload;
    },
    resetWorkflowState: (state) => {
      state.workflows = [];
      state.offset = 0;
      state.total = null;
      state.shouldRefetch = false;
    },
  },
});

export const { updateWorkflowState, updateWorkflowOffset, resetWorkflowState } = workflowSlice.actions;

export default workflowSlice.reducer;
