import { API_PATH } from "@/constants/config";
import { request } from "@/helper/http";
import { FeatureFlag } from "@/types/featureFlag";

export const fetchAll = (): Promise<FeatureFlag[]> => {
  return request({
    url: API_PATH.FEATURE_FLAG,
    method: "GET",
  });
};
