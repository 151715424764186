import { API_PATH } from "@/constants/config";
import { KVConfig, Template, TemplateId } from "@/types/template";
import { RequestOptions } from "@cinnamon/design-system";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { request } from "@helper/http";

export const getTemplate = (templateId: TemplateId): Promise<Template> => {
  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/`,
    method: "get",
  });
};

export interface PayloadTemplateCreation {
  pipeline_version: string;
  display_name: string;
}

export const createTemplate = (data: Partial<PayloadTemplateCreation>): Promise<Template> => {
  return request({
    url: API_PATH.TEMPLATES,
    method: "post",
    data,
  });
};

export const deleteTemplate = (id: TemplateId) => {
  return request({
    url: `${API_PATH.TEMPLATES}${id}/`,
    method: "delete",
  });
};

export interface UpdateTemplatePayload {
  templateId: TemplateId;
  data: Partial<Template>;
}

export const updateTemplate = (payload: UpdateTemplatePayload) => {
  const { templateId, data } = payload;

  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/`,
    method: "patch",
    data,
  });
};

export interface ImagesResponse {
  result: string[];
}

export const getTemplateImages = (templateId: TemplateId): Promise<ImagesResponse> => {
  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/images/`,
    method: "get",
    params: {
      timestamp: new Date().getTime(),
    },
  });
};

export const getTemplateThumbnails = (templateId: TemplateId): Promise<ImagesResponse> => {
  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/thumbnails/`,
    method: "get",
    params: {
      timestamp: new Date().getTime(),
    },
  });
};

export interface UploadTemplateFilePayload {
  templateId: TemplateId;
  file: File;
  config?: RequestOptions;
}

export const uploadTemplateFile = (payload: UploadTemplateFilePayload) => {
  const { templateId, file, config } = payload;

  const formData = new FormData();
  formData.append("file", file);

  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/original-file/`,
    method: "post",
    data: formData,
    ...config,
  });
};

export interface UpdateTemplateConfigsPayload {
  templateId: TemplateId;
  kv_configs: KVConfig[];
}

export const updateTemplateConfigs = (payload: UpdateTemplateConfigsPayload): Promise<Template> => {
  const { templateId, kv_configs } = payload;

  return request({
    url: `${API_PATH.TEMPLATES}${templateId}/kv-configs/`,
    method: "put",
    data: { kv_configs },
  });
};

export const templateApi = createApi({
  reducerPath: "templateApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Templates"],
  endpoints: (build) => ({
    getTemplate: build.query({
      queryFn: async (templateId: TemplateId) => {
        try {
          const data = await getTemplate(templateId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Templates"],
    }),
    createTemplate: build.mutation({
      queryFn: async (payload: PayloadTemplateCreation) => {
        try {
          const data = await createTemplate(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Templates"],
    }),
    deleteTemplate: build.mutation({
      queryFn: async (id: TemplateId) => {
        try {
          const data = await deleteTemplate(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Templates"],
    }),
    updateTemplate: build.mutation({
      queryFn: async (payload: UpdateTemplatePayload) => {
        try {
          const data = await updateTemplate(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Templates"],
    }),
    getTemplateImages: build.query({
      queryFn: async (templateId: TemplateId) => {
        try {
          const data = await getTemplateImages(templateId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Templates"],
    }),
    getTemplateThumbnails: build.query({
      queryFn: async (templateId: TemplateId) => {
        try {
          const data = await getTemplateThumbnails(templateId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Templates"],
    }),
    uploadTemplateFile: build.mutation({
      queryFn: async (payload: UploadTemplateFilePayload) => {
        try {
          const data = await uploadTemplateFile(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Templates"],
    }),
    updateTemplateConfigs: build.mutation({
      queryFn: async (payload: UpdateTemplateConfigsPayload) => {
        try {
          const data = await updateTemplateConfigs(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Templates"],
    }),
  }),
});

export const {
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
  useGetTemplateImagesQuery,
  useGetTemplateThumbnailsQuery,
  useUploadTemplateFileMutation,
  useUpdateTemplateConfigsMutation,
} = templateApi;
