import { FixFormDataEnhancementServer, IFixFormDataEnhancement } from "./fixForm";
import { BUILD_TYPE, CLASSIFICATION_GROUP_BY, EXEC_TYPE, FORM_TYPE } from "./global";
import { LLMSample } from "./llmSample";
import { Template } from "./template";
import { UserInShort } from "./user";
import { WorkflowInShort } from "./workflow";

export type PipelineId = string;

export const PIPELINES_OFFSET_DEFAULT = 0;
export const PIPELINES_LIMIT_DEFAULT = 30;

export enum PipelineStatus {
  DRAFT = "draft",
  EDITING = "editing",
  PUBLISHING = "publishing",
  PUBLISHED = "published",
  ERROR = "error",
}

export enum PipelineBuildType {
  PREBUILT = "prebuilt",
  VENDOR_CUSTOMIZED = "vendor-customized",
  CLIENT_CUSTOMIZED = "client-customized",
}

export enum LLM_AI_SETTING_MODELS {
  GPT = "gpt",
  CINNAMON_LLM = "cinnamon-llm",
}

export type ClassId = string;

export interface LLMPipelineVersion {
  llm_model_type?: LLM_AI_SETTING_MODELS;
  enable_single_field_advanced_options?: boolean;
  enable_single_field_extraction?: boolean;
  enable_table_field_advanced_options?: boolean;
  enable_table_field_extraction?: boolean;
}

export interface ServerPipeline extends LLMPipelineVersion {
  id: PipelineId;
  created_at: string;
  created_by: UserInShort;
  updated_at: string;
  updated_by: UserInShort;
  code_name: string;
  display_name: string;
  description: string;
  meta: PipelineTemplate | Record<string, never>;
  templates: Template[];
  version: string;
  status: PipelineStatus;
  build_type: BUILD_TYPE;
  exec_type: EXEC_TYPE;
  form_type: FORM_TYPE;
  workflows: WorkflowInShort[];
  isSelected: boolean;
  targets: Record<ClassId, Document>;
  target_document: string;
  versions: PipelineVersionServer[];
}

export interface Pipeline extends Omit<ServerPipeline, "targets"> {
  targets: Target[];
}

export type PipelineVersionId = string;
export type PipelineVersionTag = string;
export interface PipelineVersionServer extends LLMPipelineVersion {
  id: PipelineVersionId;
  created_at: string;
  created_by: UserInShort;
  updated_at: string;
  updated_by: UserInShort;
  published_at: string | null;
  published_by: UserInShort | null;
  current: boolean;
  templates: Template[];
  llm_samples: LLMSample[];
  version: string;
  status: PipelineStatus;
  tag: PipelineVersionTag;
  targets: Record<ClassId, Document>;
  pipeline: Pipeline;
  post_processors: FixFormDataEnhancementServer[];
}

export interface PipelineVersion extends Omit<PipelineVersionServer, "targets" | "post_processors"> {
  targets: Target[];
  post_processors: IFixFormDataEnhancement[];
}

export enum FIELD_TYPES {
  SINGLE_FIELD = "single",
  TABLE_FIELD = "table",
}

export enum PIPELINE_FIELD_TYPES {
  TEXT = "text",
  CHECKMARK = "checkmark",
  GROUP_CHECKMARK = "checkmark-group",
  BLANK = "blank",
}

export type TargetId = string;

export interface Document {
  id?: TargetId;
  order?: number;
  type?: FIELD_TYPES;
  extraction_type?: PIPELINE_FIELD_TYPES;
  description: string;
  display_name: string;
  copied_from?: TargetId | null;
}

export interface Target extends Document {
  alternative_names?: string[];
  classId: ClassId;
  class?: string;
  confidence?: number;
  isCustomField?: boolean;
  isNewField?: boolean;
}

export interface PipelineTemplate {
  post_processors: IPostPreprocessors;
  pre_processors: Record<string, boolean>;
}

export interface IPostPreprocessors {
  group_by: CLASSIFICATION_GROUP_BY[];
}

export type PIPELINE_TYPE = "prebuilt" | "vendor-customized";

export interface PipelineInShort {
  id: PipelineId;
  code_name: string | null;
  display_name: string;
  description: string | null;
  target_document: string | null;
  build_type: BUILD_TYPE;
  exec_type: EXEC_TYPE;
}
