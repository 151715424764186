import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { getSessionInfo } from "@/redux/reducer/userSlice";
import { userState } from "@/redux/store";
import Route from "@/routes";
import { CinLoaderFull } from "@cinnamon/design-system";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { fetchAllFeatureFlags } from "./redux/reducer/featureFlagSlice";

const Box = styled.div`
  display: flex;
  max-width: 100%;
`;

const App = () => {
  const dispatch = useAppDispatch();
  const { isInitialized } = useAppSelector(userState);

  useEffect(() => {
    dispatch(getSessionInfo());
    dispatch(fetchAllFeatureFlags());
  }, [dispatch]);

  if (!isInitialized) return <CinLoaderFull />;

  return (
    <Box id="global-wrapper">
      <Route />
    </Box>
  );
};

// export default process.env.NODE_ENV === 'development' ? hot(App) : App;

export default App;
