import { LOGIN_SEARCH_PARAMS, ROUTE } from "@/constants/route";
import { useIsEnableFeatureFlag } from "@/hooks";
import { useAppSelector } from "@/redux/hook";
import { userState } from "@/redux/store";
import { FLAG_TICKET } from "@/types/featureFlag";
import { FIX_FORM_CONFIG_TYPE, LLM_CONFIG_TYPE } from "@/types/global";
import {
  CinLoaderFull,
  Navigate,
  PopupProvider,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
  useSearchParams,
} from "@cinnamon/design-system";
import { FC, ReactNode, lazy, useMemo } from "react";
import AuthRoute from "./AuthRoute";

const LoginPage = lazy(() => import("@/pages/Login/Login"));
const ChangePasswordPage = lazy(() => import("@/pages/ChangePassword"));
const ForgotPasswordPage = lazy(() => import("@/pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("@/pages/ResetPassword"));
const NoPermissionPage = lazy(() => import("@/pages/NoPermission"));
const NotFoundPage = lazy(() => import("@/pages/NotFound"));

const WorkFlowDetailPage = lazy(() => import("@/pages/WorkflowDetail"));

const AnalyticsGeneralPage = lazy(() => import("@/pages/AnalyticGeneral"));

const AiPipelineManagementPage = lazy(() => import("@/pages/AiPipelines"));

const FixFormConfigPage = lazy(() => import("@/pages/PipelineConfiguration/FixedFormConfig"));
const LLMConfigPage = lazy(() => import("@/pages/PipelineConfiguration/LLMConfig"));

const TemplateEditorPage = lazy(() => import("@/pages/TemplateEditor"));

const LLMSampleEditorPage = lazy(() => import("@/pages/LLMSampleEditor"));
const LLMSampleEditorPageOld = lazy(() => import("@/pages/LLMSampleEditorOld"));
const AnalyticGeneral = lazy(() => import("@/pages/AnalyticGeneral"));

const EditorPage = lazy(() => import("@/pages/JobEditor"));
const DepartmentsPage = lazy(() => import("@/pages/Departments"));
const UserPage = lazy(() => import("@/pages/Users"));
const SettingPage = lazy(() => import("@/pages/Setting"));
const PersonalSetting = lazy(() => import("@/pages/PersonalSetting"));
const Dictionary = lazy(() => import("@/pages/Dictionary"));

const AuthLayout = lazy(() => import("@/layouts/AuthLayout"));
const UnAuthLayout = lazy(() => import("@/layouts/UnAuthLayout"));

interface RouteType {
  name?: string;
  children?: ReactNode;
}

interface ConfigRoute {
  isAuth?: boolean;
  flagsFeature?: Record<FLAG_TICKET, boolean>;
}
interface UnAuthRoute extends ConfigRoute {
  redirectTo: string | null;
}
const getUnAuthRoutes = ({ redirectTo, isAuth }: UnAuthRoute) => ({
  path: ROUTE.LOGIN.to,
  element: !isAuth ? <UnAuthLayout /> : <Navigate to={redirectTo || ROUTE.HOME.to} />,
  children: [{ path: "", element: <LoginPage /> }],
});
interface AuthRoute extends ConfigRoute {
  prevPathName: string;
  prevSearch: string;
}
const getAuthRoutes = ({ isAuth, prevPathName, prevSearch, flagsFeature }: AuthRoute) => ({
  path: ROUTE.HOME.to,
  element: isAuth ? (
    <AuthLayout />
  ) : (
    <Navigate to={`${ROUTE.LOGIN.to}?${LOGIN_SEARCH_PARAMS.REDIRECT_TO}=${prevPathName}${prevSearch}`} />
  ),
  children: [
    {
      path: "*",
      element: <Navigate to={ROUTE.NOT_FOUND.to} />,
    },
    {
      path: "",
      element: <Navigate to={ROUTE.FILE_MANAGEMENT.to} replace />,
    },
    {
      path: ROUTE.FILE_MANAGEMENT.path,
      element: <AuthRoute element={<WorkFlowDetailPage />} permissions={ROUTE.WORKFLOW.permissions} />,
    },
    {
      path: ROUTE.ANALYTICS_GENERAL.path,
      element: <AuthRoute element={<AnalyticsGeneralPage />} permissions={ROUTE.ANALYTICS_GENERAL.permissions} />,
    },
    {
      path: ROUTE.EDITOR.path,
      element: <AuthRoute element={<EditorPage />} permissions={ROUTE.EDITOR.permissions} />,
    },
    {
      path: ROUTE.CHANGE_PASSWORD.path,
      element: <AuthRoute element={<ChangePasswordPage />} permissions={ROUTE.CHANGE_PASSWORD.permissions} />,
    },
    {
      path: ROUTE.PERSONAL_SETTING.path,
      element: <AuthRoute element={<PersonalSetting />} permissions={ROUTE.PERSONAL_SETTING.permissions} />,
    },
    {
      path: ROUTE.AI_PIPELINE.path,
      element: <AuthRoute element={<AiPipelineManagementPage />} permissions={ROUTE.AI_PIPELINE.permissions} />,
    },
    {
      path: ROUTE.FIX_FORM_CONFIG_FIELDS.path,
      element: (
        <AuthRoute
          element={<FixFormConfigPage type={FIX_FORM_CONFIG_TYPE.FIELDS} />}
          permissions={ROUTE.FIX_FORM_CONFIG_FIELDS.permissions}
        />
      ),
    },
    {
      path: ROUTE.FIX_FORM_CONFIG_TEMPLATES.path,
      element: (
        <AuthRoute
          element={<FixFormConfigPage type={FIX_FORM_CONFIG_TYPE.TEMPLATES} />}
          permissions={ROUTE.FIX_FORM_CONFIG_TEMPLATES.permissions}
        />
      ),
    },
    {
      path: ROUTE.FIX_FORM_CONFIG_DATA_ENHANCEMENT.path,
      element: (
        <AuthRoute
          element={<FixFormConfigPage type={FIX_FORM_CONFIG_TYPE.DATA_ENHANCEMENT} />}
          permissions={ROUTE.FIX_FORM_CONFIG_DATA_ENHANCEMENT.permissions}
        />
      ),
    },
    {
      path: ROUTE.FIX_FORM_CONFIG_RELEASES.path,
      element: (
        <AuthRoute
          element={<FixFormConfigPage type={FIX_FORM_CONFIG_TYPE.RELEASES} />}
          permissions={ROUTE.FIX_FORM_CONFIG_RELEASES.permissions}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_AI_SETTING_MODEL.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.AI_SETTING_MODEL} />}
          permissions={ROUTE.LLM_CONFIG_AI_SETTING_MODEL.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_SINGLE_FIELDS.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.SINGLE_FIELDS} />}
          permissions={ROUTE.LLM_CONFIG_SINGLE_FIELDS.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_TABLE_FIELDS.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.TABLE_FIELDS} />}
          permissions={ROUTE.LLM_CONFIG_TABLE_FIELDS.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_SAMPLES.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.SAMPLES} />}
          permissions={ROUTE.LLM_CONFIG_SAMPLES.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_DATA_ENHANCEMENT.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.DATA_ENHANCEMENT} />}
          permissions={ROUTE.LLM_CONFIG_DATA_ENHANCEMENT.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_CONFIG_RELEASES.path,
      element: (
        <AuthRoute
          element={<LLMConfigPage type={LLM_CONFIG_TYPE.RELEASES} />}
          permissions={ROUTE.LLM_CONFIG_RELEASES.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-291"]]}
        />
      ),
    },
    {
      path: ROUTE.LLM_SAMPLE_EDITOR.path,
      element: (
        <AuthRoute
          element={flagsFeature?.[FLAG_TICKET["FSH2406-295"]] ? <LLMSampleEditorPage /> : <LLMSampleEditorPageOld />}
          permissions={ROUTE.LLM_SAMPLE_EDITOR.permissions}
          isNavigateNotFound={!flagsFeature?.[FLAG_TICKET["FSH2406-271"]]}
        />
      ),
    },
    {
      path: ROUTE.TEMPLATE_EDITOR.path,
      element: <AuthRoute element={<TemplateEditorPage />} permissions={ROUTE.TEMPLATE_EDITOR.permissions} />,
    },
    {
      path: ROUTE.ACCESS_PERMISSION.path,
      element: <Navigate to={ROUTE.ACCESS_PERMISSION_USERS.to} replace />,
    },
    {
      path: ROUTE.ACCESS_PERMISSION_USERS.path,
      element: <AuthRoute element={<UserPage />} permissions={ROUTE.ACCESS_PERMISSION_USERS.permissions} />,
    },
    {
      path: ROUTE.ACCESS_PERMISSION_DEPARTMENTS.path,
      element: (
        <AuthRoute element={<DepartmentsPage />} permissions={ROUTE.ACCESS_PERMISSION_DEPARTMENTS.permissions} />
      ),
    },
    {
      path: ROUTE.SETTING.path,
      element: <AuthRoute element={<SettingPage />} permissions={ROUTE.SETTING.permissions} />,
    },
    { path: ROUTE.ANALYTICS.path, element: <Navigate to={ROUTE.ANALYTICS_GENERAL.to} replace /> },
    {
      path: ROUTE.ANALYTICS_GENERAL.path,
      element: <AuthRoute element={<AnalyticGeneral />} permissions={ROUTE.ANALYTICS_GENERAL.permissions} />,
    },
    {
      path: ROUTE.DICTIONARY.path,
      element: <AuthRoute element={<Dictionary />} permissions={ROUTE.DICTIONARY.permissions} />,
    },
  ],
});

const getOtherRoutes = () => [
  { path: ROUTE.NO_PERMISSION.path, element: <NoPermissionPage /> },
  { path: ROUTE.NOT_FOUND.path, element: <NotFoundPage /> },
  { path: ROUTE.FORGOT_PASSWORD.path, element: <ForgotPasswordPage /> },
  { path: ROUTE.RESET_PASSWORD.path, element: <ResetPasswordPage /> },
];

const Root: FC = () => {
  const { user, isInitialized, isAuthenticated } = useAppSelector(userState);
  const { pathname: prevPathName, search: prevSearch } = useLocation();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(LOGIN_SEARCH_PARAMS.REDIRECT_TO);
  const isAuth = isAuthenticated && user;

  const flagLLMConfig = useIsEnableFeatureFlag(FLAG_TICKET["FSH2406-291"]);
  const flagLLMSampleEditor = useIsEnableFeatureFlag(FLAG_TICKET["FSH2406-271"]);
  const flagLLMSampleEditorLayoutNew = useIsEnableFeatureFlag(FLAG_TICKET["FSH2406-295"]);

  const flagsFeature = useMemo(
    (): Record<string, boolean> => ({
      [FLAG_TICKET["FSH2406-291"]]: flagLLMConfig,
      [FLAG_TICKET["FSH2406-271"]]: flagLLMSampleEditor,
      [FLAG_TICKET["FSH2406-295"]]: flagLLMSampleEditorLayoutNew,
    }),
    [flagLLMConfig, flagLLMSampleEditor, flagLLMSampleEditorLayoutNew]
  );

  const unAuthRoutes = useMemo(() => getUnAuthRoutes({ redirectTo, isAuth: !!isAuth }), [isAuth, redirectTo]);
  const authRoutes = useMemo(
    () => getAuthRoutes({ isAuth: !!isAuth, prevPathName, prevSearch, flagsFeature }),
    [isAuth, prevPathName, prevSearch, flagsFeature]
  );
  const otherRoutes = getOtherRoutes();

  if (!isInitialized) {
    return <CinLoaderFull />;
  }

  return (
    <Routes>
      {/* unAuthRoute */}
      <Route path={unAuthRoutes.path} element={unAuthRoutes.element}>
        {unAuthRoutes.children.map((route) => (
          <Route key={`${unAuthRoutes.path}-${route.path}`} path={route.path} element={route.element} />
        ))}
      </Route>

      {/* authRoute */}
      <Route path={authRoutes.path} element={authRoutes.element}>
        {authRoutes.children.map((route) => (
          <Route key={`${authRoutes.path}-${route.path}`} path={route.path} element={route.element} />
        ))}
      </Route>

      {/* other Auth */}
      {otherRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

const RouteConfig: FC<RouteType> = (): JSX.Element => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: (
        <PopupProvider>
          <Root />
        </PopupProvider>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RouteConfig;
