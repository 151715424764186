import { APIQuery, FROM_PAGE_MANAGEMENT, RouteSchema, USER_PERMISSION } from "@/types/global";
import { getRouteSearchStr } from "@helper/utility";
import { FiServer } from "react-icons/fi";
import { PAGE_LIMITS } from "./picklist";

export const DEFAULT_PAGE_QUERY: APIQuery = {
  page: 1,
  max_results: PAGE_LIMITS[0],
};

export const DEFAULT_META: APIQuery = {
  total: 0,
  skip: 0,
  limit: 10,
};

export enum ROUTE_KEY {
  HOME = "HOME",
  LOGIN = "LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  FILES = "FILES",
  EDITOR = "EDITOR",
  FILE_MANAGEMENT = "FILE_MANAGEMENT",
  WORKFLOW = "WORKFLOW",
  WORKFLOW_NEW = "WORKFLOW_NEW",
  WORKFLOW_NEW_CLASSIFICATION = "WORKFLOW_NEW_CLASSIFICATION",
  WORKFLOW_EDIT = "WORKFLOW_EDIT",
  WORKFLOW_LIST = "WORKFLOW_LIST",
  AI_PIPELINE = "AI_PIPELINE",
  SETTING = "SETTING",
  ACCESS_PERMISSION = "ACCESS_PERMISSION",
  ACCESS_PERMISSION_USERS = "ACCESS_PERMISSION_USERS",
  ACCESS_PERMISSION_DEPARTMENTS = "ACCESS_PERMISSION_DEPARTMENTS",
  ANALYTICS = "ANALYTICS",
  ANALYTICS_GENERAL = "ANALYTICS_GENERAL",
  ANALYTICS_PERFORMANCE = "ANALYTICS_PERFORMANCE",
  PERSONAL_SETTING = "PERSONAL_SETTING",
  FIX_FORM_CONFIG_FIELDS = "FIX_FORM_CONFIG_FIELDS",
  FIX_FORM_CONFIG_TEMPLATES = "FIX_FORM_CONFIG_TEMPLATES",
  FIX_FORM_CONFIG_DATA_ENHANCEMENT = "FIX_FORM_CONFIG_DATA_ENHANCEMENT",
  FIX_FORM_CONFIG_RELEASES = "FIX_FORM_CONFIG_RELEASES",
  TEMPLATE_EDITOR = "TEMPLATE_EDITOR",
  LLM_SAMPLE_EDITOR = "LLM_SAMPLE_EDITOR",
  LLM_CONFIG_AI_SETTING_MODEL = "LLM_CONFIG_AI_SETTING_MODEL",
  LLM_CONFIG_SINGLE_FIELDS = "LLM_CONFIG_SINGLE_FIELDS",
  LLM_CONFIG_TABLE_FIELDS = "LLM_CONFIG_TABLE_FIELDS",
  LLM_CONFIG_SAMPLES = "LLM_CONFIG_SAMPLES",
  LLM_CONFIG_DATA_ENHANCEMENT = "LLM_CONFIG_DATA_ENHANCEMENT",
  LLM_CONFIG_RELEASES = "LLM_CONFIG_RELEASES",
  DICTIONARY = "DICTIONARY",
  NO_PERMISSION = "NO_PERMISSION",
  NOT_FOUND = "NOT_FOUND",
}

export const ROUTE: Record<ROUTE_KEY, RouteSchema> = {
  HOME: {
    to: "/",
    path: "",
  },
  LOGIN: {
    to: "/login",
    path: "login",
  },
  FORGOT_PASSWORD: {
    to: "/forgot-password",
    path: "forgot-password",
  },
  RESET_PASSWORD: {
    to: "/reset-password",
    path: "reset-password/:reset_token",
  },
  CHANGE_PASSWORD: {
    to: "/change-password",
    path: "change-password",
  },
  FILES: {
    to: "/files",
    path: "files",
    icon: FiServer as any,
    text: "COMMON.FILE_MANAGEMENT",
    search: getRouteSearchStr({ ...DEFAULT_PAGE_QUERY, sort: "-created_at" }),
  },
  EDITOR: {
    to: "/editor",
    path: "editor",
  },
  FILE_MANAGEMENT: {
    to: "/file",
    path: "file",
    text: "COMMON.FILE",
    permissions: [USER_PERMISSION.VIEW_JOB],
  },
  WORKFLOW: {
    to: "/workflows",
    path: "workflows",
    text: "COMMON.WORKFLOW",
    search: getRouteSearchStr({ ...DEFAULT_PAGE_QUERY, sort: "-created_at" }),
    permissions: [USER_PERMISSION.VIEW_JOB],
  },
  WORKFLOW_NEW: {
    to: "/workflows/new-ocr",
    path: "workflows/new-ocr",
    permissions: [USER_PERMISSION.ADD_WORKFLOW],
  },
  WORKFLOW_NEW_CLASSIFICATION: {
    to: "/workflows/new-classification",
    path: "workflows/new-classification",
    permissions: [USER_PERMISSION.ADD_WORKFLOW],
  },
  WORKFLOW_EDIT: {
    to: "/workflows/edit",
    path: "/workflows/edit/:workflow_id",
    permissions: [USER_PERMISSION.CHANGE_WORKFLOW],
  },
  WORKFLOW_LIST: {
    to: "/workflows/list",
    path: "workflows/list",
    permissions: [USER_PERMISSION.VIEW_WORKFLOW],
  },
  AI_PIPELINE: {
    to: "/ai-pipelines",
    path: "ai-pipelines",
    text: "COMMON.AI_PIPELINE",
    permissions: [USER_PERMISSION.VIEW_PIPELINE],
  },
  SETTING: {
    to: "/setting",
    path: "setting",
    text: "COMMON.SETTING",
    permissions: [USER_PERMISSION.VIEW_SYSTEM_SETTINGS],
  },
  ACCESS_PERMISSION: {
    to: "/access-permissions",
    path: "access-permissions",
    text: "COMMON.USER",
    permissions: [USER_PERMISSION.VIEW_USER],
  },
  ACCESS_PERMISSION_USERS: {
    to: "/access-permissions/users",
    path: "access-permissions/users",
    text: "COMMON.USER",
    permissions: [USER_PERMISSION.VIEW_USER],
  },
  ACCESS_PERMISSION_DEPARTMENTS: {
    to: "/access-permissions/departments",
    path: "access-permissions/departments",
    text: "COMMON.DEPARTMENT",
    permissions: [USER_PERMISSION.VIEW_TEAM],
  },
  ANALYTICS: {
    to: "/analytics",
    path: "analytics",
    text: "COMMON.ANALYTIC",
    permissions: [USER_PERMISSION.VIEW_PIPELINE_STATISTIC],
  },
  ANALYTICS_GENERAL: {
    to: "/analytics/general",
    path: "analytics/general",
    text: "COMMON.ANALYTIC_GENERAL",
    permissions: [USER_PERMISSION.VIEW_PIPELINE_STATISTIC],
  },
  ANALYTICS_PERFORMANCE: {
    to: "/analytics/performance",
    path: "analytics/performance",
    text: "COMMON.ANALYTIC_PERFORMANCE",
    permissions: [USER_PERMISSION.VIEW_PIPELINE_STATISTIC],
  },
  PERSONAL_SETTING: {
    to: "/personal-setting",
    path: "personal-setting",
    text: "COMMON.PERSONAL_SETTING",
  },
  FIX_FORM_CONFIG_FIELDS: {
    to: "/ai-pipelines/fix-form-config/fields",
    path: "ai-pipelines/fix-form-config/fields/:pipelineId",
    text: "PAGES.FIX_FORM_CONFIG.FIELDS",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  FIX_FORM_CONFIG_TEMPLATES: {
    to: "/ai-pipelines/fix-form-config/templates",
    path: "ai-pipelines/fix-form-config/templates/:pipelineId",
    text: "PAGES.FIX_FORM_CONFIG.TEMPLATES",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  FIX_FORM_CONFIG_DATA_ENHANCEMENT: {
    to: "/ai-pipelines/fix-form-config/data-enhancement",
    path: "ai-pipelines/fix-form-config/data-enhancement/:pipelineId",
    text: "PAGES.FIX_FORM_CONFIG.DATA_ENHANCEMENT",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  FIX_FORM_CONFIG_RELEASES: {
    to: "/ai-pipelines/fix-form-config/releases",
    path: "ai-pipelines/fix-form-config/releases/:pipelineId",
    text: "PAGES.FIX_FORM_CONFIG.RELEASES",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  TEMPLATE_EDITOR: {
    to: "/ai-pipelines/template-editor",
    path: "ai-pipelines/template-editor/:templateId",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_SAMPLE_EDITOR: {
    to: "/ai-pipelines/sample-editor",
    path: "ai-pipelines/sample-editor/:sampleId",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_AI_SETTING_MODEL: {
    to: "/ai-pipelines/llm-ocr-config/ai-setting",
    path: "ai-pipelines/llm-ocr-config/ai-setting/:pipelineId",
    text: "PAGES.LLM_CONFIG.AI_SETTING_MODEL",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_SINGLE_FIELDS: {
    to: "/ai-pipelines/llm-ocr-config/single-fields",
    path: "ai-pipelines/llm-ocr-config/single-fields/:pipelineId",
    text: "PAGES.LLM_CONFIG.SINGLE_FIELDS",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_TABLE_FIELDS: {
    to: "/ai-pipelines/llm-ocr-config/table-fields",
    path: "ai-pipelines/llm-ocr-config/table-fields/:pipelineId",
    text: "PAGES.LLM_CONFIG.TABLE_FIELDS",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_SAMPLES: {
    to: "/ai-pipelines/llm-ocr-config/samples",
    path: "ai-pipelines/llm-ocr-config/samples/:pipelineId",
    text: "PAGES.LLM_CONFIG.SAMPLES",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_DATA_ENHANCEMENT: {
    to: "/ai-pipelines/llm-ocr-config/data-enhancement",
    path: "ai-pipelines/llm-ocr-config/data-enhancement/:pipelineId",
    text: "PAGES.LLM_CONFIG.DATA_ENHANCEMENT",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  LLM_CONFIG_RELEASES: {
    to: "/ai-pipelines/llm-ocr-config/releases",
    path: "ai-pipelines/llm-ocr-config/releases/:pipelineId",
    text: "PAGES.LLM_CONFIG.RELEASES",
    permissions: [USER_PERMISSION.CHANGE_PIPELINE],
  },
  DICTIONARY: {
    to: "/dictionary",
    path: "dictionary",
    text: "COMMON.DICTIONARY",
    permissions: [],
  },
  NO_PERMISSION: {
    to: "/401",
    path: "401",
  },
  NOT_FOUND: {
    to: "/404",
    path: "404",
  },
};

export const NAVBAR_ITEMS = [ROUTE.FILE_MANAGEMENT, ROUTE.AI_PIPELINE, ROUTE.ACCESS_PERMISSION, ROUTE.DICTIONARY];

export const ACCESS_PERMISSION_ROUTES = [ROUTE.ACCESS_PERMISSION_USERS, ROUTE.ACCESS_PERMISSION_DEPARTMENTS];

export const ANALYTICS_ROUTES = [ROUTE.ANALYTICS_GENERAL];

export const FIX_FORM_CONFIG_ROUTES = [
  ROUTE.FIX_FORM_CONFIG_FIELDS,
  ROUTE.FIX_FORM_CONFIG_TEMPLATES,
  ROUTE.FIX_FORM_CONFIG_DATA_ENHANCEMENT,
  ROUTE.FIX_FORM_CONFIG_RELEASES,
];

export const LLM_CONFIG_ROUTES = [
  ROUTE.LLM_CONFIG_AI_SETTING_MODEL,
  ROUTE.LLM_CONFIG_SINGLE_FIELDS,
  ROUTE.LLM_CONFIG_TABLE_FIELDS,
  ROUTE.LLM_CONFIG_SAMPLES,
  ROUTE.LLM_CONFIG_DATA_ENHANCEMENT,
  ROUTE.LLM_CONFIG_RELEASES,
];

export const LOGIN_SEARCH_PARAMS = {
  REDIRECT_TO: "redirectTo",
};

export const FROM_PAGE_MANAGEMENT_ROUTE = {
  [FROM_PAGE_MANAGEMENT.FILE_MANAGEMENT]: ROUTE.WORKFLOW.to,
  [FROM_PAGE_MANAGEMENT.PIPELINE_MANAGEMENT]: ROUTE.AI_PIPELINE.to,
  [FROM_PAGE_MANAGEMENT.WORKFLOW_MANAGEMENT]: ROUTE.WORKFLOW_LIST.to,
};
