export enum FLAG_TICKET {
  "FSH2403ST-20" = "FSH2403ST-20", // pipeline page with llm configuration

  "FSH2406-291" = "FSH2406-291", // llm config
  "FSH2406-271" = "FSH2406-271", // sample editor

  "FSH2406-295" = "FSH2406-295", // sample editor layout
  "FSH2406-414" = "FSH2406-414", // analytics enhancement

  "FSH2406-134" = "FSH2406-134", // New UI OCR Editor
  "FSH2406-415" = "FSH2406-415", // toolbar
  "FSH2406-131" = "FSH2406-131", // top information bar
}

export type FeatureFlagId = number;

export interface FeatureFlag {
  id: FeatureFlagId;
  ticket: FLAG_TICKET;
  description: string;
  enable: boolean;
}
