import { API_PATH } from "@/constants/config";
import { request } from "@/helper/http";
import { StatsData, StatsDataPayload, WorkflowStatsDataPayload } from "@/types/statistic";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

const getUrlStatsPipeline = (params: StatsDataPayload) => {
  return `${API_PATH.STATS}/pipelines/${params.month ? `by-month` : "by-year"}/`;
};
export const getAnalyticData = (year: number, month: number, day?: number) => {
  const params = { day, month, year };
  return request({
    method: "get",
    url: getUrlStatsPipeline({
      month,
      year,
    }),
    params,
  });
};

export const statisticApi = createApi({
  reducerPath: "statisticApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["WorkflowStats", "PipelineStats"],
  endpoints: (build) => ({
    getStatsYearsAvailable: build.query({
      queryFn: async () => {
        try {
          const data: number[] = await request({
            method: "GET",
            url: `${API_PATH.STATS}/years-available/`,
          });

          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    getWorkflowStatsData: build.query({
      queryFn: async (params: WorkflowStatsDataPayload) => {
        try {
          const url = `${API_PATH.STATS}/workflows/${params.month ? `by-month` : "by-year"}/`;
          const data: StatsData = await request({
            method: "GET",
            url,
            params,
          });

          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["WorkflowStats"],
    }),
    getPipelineStatsData: build.query({
      queryFn: async (params: StatsDataPayload) => {
        try {
          const data: StatsData = await request({
            method: "GET",
            url: getUrlStatsPipeline(params),
            params,
          });

          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["PipelineStats"],
    }),
  }),
});

export const { useGetPipelineStatsDataQuery, useGetWorkflowStatsDataQuery, useGetStatsYearsAvailableQuery } =
  statisticApi;
