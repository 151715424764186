import { ABLE_TO_LOCK_STATUSES } from "@/constants/global";
import { workflowToModelType } from "@/helper/workflowToModelType";
import { FileAPIQuery, Job, JobData, JobId, RerunPayload } from "@/types/file";
import { APIResponse, ILinecutAnnotationPayLoad } from "@/types/global";
import { RequestOptions } from "@cinnamon/design-system";
import { API_PATH } from "@constants/config";
import { request } from "@helper/http";
import { downloadFromBinary, getFileNameFromDisposition } from "@helper/utility";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export const getFiles = async (params: FileAPIQuery): Promise<APIResponse<Job>> => {
  const response: APIResponse<Job> = await request({ url: API_PATH.JOBS, method: "get", params });
  const normalizedJobs = response.results.map((item, i) => ({
    ...item,
    number: i + 1 + (params?.offset ?? 0),
    model_type: item?.model_type || workflowToModelType(item.workflow),
  }));
  return { ...response, results: normalizedJobs };
};

export const getJob = (jobId: string): Promise<Job> => {
  return request({ url: `${API_PATH.JOBS}${jobId}/`, method: "GET" });
};

export const lockJob = (jobId: string): Promise<Job> => {
  return request({ url: API_PATH.LOCK_JOB(jobId), method: "POST" });
};

export const unLockJob = (jobId: string): Promise<Job> => {
  return request({ url: API_PATH.LOCK_JOB(jobId), method: "DELETE" });
};

interface UploadFileParams {
  file: File;
  departmentId: string;
  config?: RequestOptions;
}

export async function uploadFile(params: UploadFileParams) {
  const { file, departmentId, config } = params;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("department_id", departmentId);

  // return instance.post("/jobs/", formData, { ...config });
  return request({
    url: API_PATH.JOBS,
    method: "post",
    data: formData,
    ...config,
  });
}

export async function downloadResult(jobId: JobId) {
  const result = await request({
    url: API_PATH.JOB_DOWNLOAD(jobId),
    method: "get",
    responseType: "blob",
    shouldGetFullResponse: true,
  });
  const fileName = getFileNameFromDisposition(result.headers["content-disposition"]);
  downloadFromBinary(result.data, fileName);
}

export async function downloadResults(ids: string) {
  const result = await request({
    url: `${API_PATH.JOBS_DOWNLOAD_BATCH}`,
    method: "get",
    responseType: "blob",
    shouldGetFullResponse: true,
    params: { ids },
  });
  const fileName = getFileNameFromDisposition(result.headers["content-disposition"]);
  downloadFromBinary(result.data, fileName);
}

export function deleteJob(id: string) {
  return request({
    url: `${API_PATH.JOBS}${id}/`,
    method: "delete",
  });
}

export function bulkDeleteJob(ids: string) {
  return request({
    url: `${API_PATH.BULK_DELETE_JOB}`,
    method: "delete",
    params: { ids },
  });
}
export async function fetchImage(jobId: string) {
  return request({
    url: API_PATH.STATIC(jobId, "parsed"),
    method: "get",
    responseType: "blob",
  });
}

export async function getJobResult(jobId: string) {
  return request({
    url: API_PATH.JOB_DATA(jobId),
    method: "get",
    params: {
      show_result_for: "confidence=highest",
    },
  });
}

export async function getJobImages(jobId: string) {
  return request({
    url: `${API_PATH.JOBS}${jobId}/images/?pages=all`,
    method: "get",
  });
}

export async function getJobThumbnails(jobId: string) {
  return request({
    url: `${API_PATH.JOBS}${jobId}/thumbnails/?pages=all`,
    method: "get",
  });
}

export async function updateJobResult(jobId: string, data: unknown) {
  return request({
    url: `${API_PATH.JOBS}${jobId}/result/update/`,
    method: "patch",
    data,
  });
}

export async function updateLineCut({
  jobId,
  annotations,
}: {
  jobId: string;
  annotations: Record<string, ILinecutAnnotationPayLoad>;
}) {
  return request({
    url: `${API_PATH.JOBS}${jobId}/linecut/`,
    method: "patch",
    data: {
      updates: annotations,
    },
  });
}

export interface RerunResponse {
  success: Job[];
  failure: Job[];
}

export const rerunMultipleJobs = (payload: RerunPayload): Promise<RerunResponse> => {
  return request({
    url: `${API_PATH.RERUN_MULTIPLE_ERROR_JOBS}`,
    method: "post",
    data: payload,
  });
};

export interface UpdateJobResultMutationParams {
  jobId: JobId;
  payload: unknown;
}

export const jobApi = createApi({
  reducerPath: "jobApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Jobs", "Job"],
  endpoints: (build) => ({
    getJobs: build.query({
      queryFn: async (payload: FileAPIQuery) => {
        try {
          const data = await getFiles(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Jobs"],
    }),
    getJobData: build.query<JobData, string>({
      queryFn: async (jobId) => {
        try {
          const [job, images, thumbnails] = await Promise.all([
            getJobResult(jobId),
            getJobImages(jobId),
            getJobThumbnails(jobId),
          ]);

          if (ABLE_TO_LOCK_STATUSES.includes(job?.status)) {
            await lockJob(jobId);
          }

          return {
            data: {
              job,
              imageUrls: images.result,
              thumbnailUrls: thumbnails.result,
            },
          };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (_result, _error, jobId) => [{ type: "Job", id: jobId }],
    }),
    isEmptyWorkflow: build.query({
      queryFn: async (workflow?: string) => {
        try {
          const response = await getFiles({ workflow, limit: 1 });
          return { data: !response.results.length };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Jobs"],
    }),
    rerunMultipleJobs: build.mutation({
      queryFn: async (payload: RerunPayload) => {
        try {
          const data = await rerunMultipleJobs(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    deleteJob: build.mutation({
      queryFn: async (id: JobId) => {
        try {
          const data = await deleteJob(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    bulkDeleteJob: build.mutation({
      queryFn: async (ids: string) => {
        try {
          const data = await bulkDeleteJob(ids);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    updateJobResult: build.mutation({
      queryFn: async (params: UpdateJobResultMutationParams) => {
        try {
          const { jobId, payload } = params;
          const data = await updateJobResult(jobId, payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    lockJob: build.mutation({
      queryFn: async (id: JobId) => {
        try {
          const data = await lockJob(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    unLockJob: build.mutation({
      queryFn: async (id: JobId) => {
        try {
          const data = await unLockJob(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (_result, _error, jobId) => [{ type: "Job", id: jobId }, { type: "Jobs" }],
    }),
    bulkConfirmJob: build.mutation({
      queryFn: async (ids: string[]) => {
        try {
          const data = await request({
            url: API_PATH.BULK_CONFIRM_JOB,
            method: "patch",
            data: { ids },
          });

          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Jobs"],
    }),
    invalidateJobs: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ["Jobs"],
    }),
  }),
});

export const {
  useGetJobsQuery,
  useIsEmptyWorkflowQuery,
  useRerunMultipleJobsMutation,
  useDeleteJobMutation,
  useBulkDeleteJobMutation,
  useUpdateJobResultMutation,
  useLockJobMutation,
  useUnLockJobMutation,
  useBulkConfirmJobMutation,
  useInvalidateJobsMutation,
  useGetJobDataQuery,
} = jobApi;
