import { LANGUAGE_CODE } from "@/constants/global";
import { en, jp, initReactI18next } from "@cinnamon/design-system";
import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import merge from "lodash/merge";
import { APP_CONFIG } from "../constants/config";

i18n
  .use(
    // merge language project with language design system
    resourcesToBackend(async (language: string) => {
      try {
        // fetch locales from public folder
        const mainLang = await fetch(
          `${import.meta.env.VITE_PUBLIC_URL || ""}/locales/${language}/translation.json`
        ).then((res) => res.json());
        const designSystemLang = language === LANGUAGE_CODE.JAPANESE ? jp : en;

        return merge(designSystemLang, mainLang);
      } catch (e) {
        console.log(`🚀 SLOG (${new Date().toLocaleTimeString()}): ➡ resourcesToBackend ➡ e:`, e);
      }
    })
  ) // Translation files in /public/locales
  .use(initReactI18next)
  .init({
    fallbackLng: APP_CONFIG.DEFAULT_LANGUAGE,
    lng: APP_CONFIG.DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
