import {
  AuthenticatedTemplate,
  AzureADProvider,
  CinDesignSystemProvider,
  CinErrorBoundary,
  CinLoaderFull,
  UnauthenticatedTemplate,
  cinTheme,
} from "@cinnamon/design-system";
import "@helper/i18n";
import store from "@redux/store";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.tsx";
import { AZURE_CONFIG } from "./constants/config.ts";
import "./index.css";
import Unauthenticated from "./pages/Unauthenticated";

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Suspense fallback={<CinLoaderFull />}>
      <Provider store={store}>
        <CinDesignSystemProvider theme={cinTheme}>
          <AzureADProvider configs={AZURE_CONFIG}>
            <AuthenticatedTemplate>
              <CinErrorBoundary>
                <App />
              </CinErrorBoundary>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Unauthenticated />
            </UnauthenticatedTemplate>
          </AzureADProvider>
        </CinDesignSystemProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);
