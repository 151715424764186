import { OcrResult } from "@/pages/Editor/type";
import { DepartmentInShort } from "./department";
import { APIQuery, EXEC_TYPE } from "./global";
import { LLMSample } from "./llmSample";
import { Template } from "./template";
import { Metadata, UserInShort } from "./user";
import { Workflow } from "./workflow";
export type FileType = "image/jpeg" | "application/json" | "application/pdf";

export type FileExtension = ".pdf" | ".jpge" | ".jpg";

export enum MODEL_TYPE {
  ORDER_FORM = "order_form",
  DELIVERY_FORM = "delivery_form",
  OTHERS = "others",
}

export enum ORDER_FORM_KEY {
  DOCUMENT_TITLE = "document_title",
  COMPANY_NAME = "company_name",
  COMPANY_DEPARTMENT_NAME = "company_department_name",
  CONSUMER = "consumer",
  DESTINATION_COMPANY_NAME = "destination_company_name",
  ORDER_DATE = "order_date",
  TOTAL_AMOUNT = "total_amount",
  ORDER_NUMBER = "order_number",
  PURCHASE_ORDER_NUMBER = "purchase_order_number",
  PROJECT_TITLE = "item_name",
}

export enum DELIVERY_FORM_KEY {
  DOCUMENT_TITLE = "document_title",
  SUPPLIER_NAME = "company_name",
  PROJECT_TITLE = "item_name",
  PURCHASE_ORDER_NUMBER = "purchase_order_number",
  DELIVERY_DESTINATION = "destination_company_name",
  DELIVERY_DATE = "delivery_date",
  CONTRACTOR_CODE = "contractor_code",
}

export enum JOB_STATUS {
  PENDING = 1,
  PROCESSING = 2,
  NEED_TO_REVIEW = 3,
  AWAITING_APPROVAL = 5,
  DONE = 4,
  EDITING = 8,
  ERROR = 9,
}

export interface IOFile extends Omit<File, "id"> {
  category: string;
  created_at: Date;
  file_name: string;
  id: string;
  job_id: string;
  media_type: FileType;
  size: number;
  updated_at: Date;
  uri: string;
}

export interface FilterFile extends Partial<File> {
  "input_file.file_name": string;
}

export interface FileMeta {
  filename: string;
  extension: FileExtension;
}

export interface FileAPIQuery extends Omit<APIQuery, "status"> {
  workflow?: string;
  file_name?: string;
  status?: JOB_STATUS;
  filter?: Record<string, any>;
}

export interface InputFile {
  id: string;
  created_at: string;
  created_by: UserInShort;
  updated_at: string;
  updated_by: UserInShort;
  file: string;
  file_name: string;
  media_type: string;
  size: number;
  uri: string;
}

export interface AIError {
  code: string;
  message: string;
}

export interface ResultLock {
  acquired_by: UserInShort;
  acquired_at: string;
  expired_at: string;
}

export type JobId = string;

type ResultType = OcrResult;

export interface IFewshotSample {
  llm_sample: Pick<LLMSample, "id" | "display_name">;
  relevant_score: number;
  page_idx: number;
}

export interface Job {
  id: JobId;
  created_at: string;
  created_by: UserInShort;
  updated_at: string;
  updated_by: UserInShort;
  confirmed_at: string;
  confirmed_by: UserInShort;
  approved_at: string;
  approved_by: UserInShort;
  exec_type: EXEC_TYPE;
  file_name: string;
  page_count: number;
  status: JOB_STATUS;
  result_updated_at: string;
  result_updated_by: UserInShort;
  started_at: string;
  finished_at: string;
  input_file: InputFile;
  workflow: Workflow;
  result: Record<string, ResultType>;
  ai_error: AIError | null;
  result_lock: ResultLock | null;
  ocr_jobs: string[];
  metadata: Metadata;
  number: number;
  fixed_template?: Template;
  fewshot_samples?: IFewshotSample[];
  parent_job: Job | null;
  department: DepartmentInShort;
  result_approver: UserInShort;
  model_type: MODEL_TYPE;
  result_by_file: ResultByFile;
}

export interface RerunPayload {
  workflows?: string[];
  jobs?: string[];
}

export interface FormGroup {
  form_idx: number;
  ls_page: number[];
}

export interface ResultByFile {
  form_groups: FormGroup[];
  form_type: MODEL_TYPE;
  template_id: string;
}

export interface JobData {
  job: Job;
  imageUrls: string[];
  thumbnailUrls: string[];
}
