import { useAppSelector } from "@/redux/hook";
import { userState } from "@/redux/store";
import { USER_PERMISSION } from "@/types/global";
import { useCallback } from "react";

export const useHasPermissions = () => {
  const { scope } = useAppSelector(userState);

  return useCallback(
    (permissions: USER_PERMISSION[]) => {
      return permissions.every((permission) => scope.split(" ").includes(permission));
    },
    [scope]
  );
};
