import { rtkInvalidateOn } from "@/helper/rtkInvalidateOn";
import { makeIdTemp } from "@/helper/utility";
import {
  FixFormDataEnhancementServer,
  FixFormDataEnhancementValue,
  FORMAT_TEXT_TYPE_DATA_ENHANCEMENT,
  IFieldInfo,
  IFixFormDataEnhancement,
  STEP_TYPE_DATA_ENHANCEMENT,
} from "@/types/fixForm";
import { APIQuery, APIResponse, FORM_TYPE } from "@/types/global";
import {
  Document,
  LLM_AI_SETTING_MODELS,
  Pipeline,
  PipelineId,
  PIPELINES_LIMIT_DEFAULT,
  PIPELINES_OFFSET_DEFAULT,
  PipelineVersion,
  PipelineVersionServer,
  ServerPipeline,
  TargetId,
} from "@/types/pipeline";
import { Template } from "@/types/template";
import { API_PATH } from "@constants/config";
import { request } from "@helper/http";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { keys } from "lodash";

export const normalizePipeline = (serverPipeline: ServerPipeline): Pipeline => {
  if (serverPipeline.form_type === FORM_TYPE.FIXED_FORM) {
    return {
      ...serverPipeline,
      isSelected: false,
      targets: keys(serverPipeline.targets).map((classId) => ({
        classId,
        ...serverPipeline.targets[classId],
      })),
    };
  }

  return {
    ...serverPipeline,
    isSelected: false,
    targets: keys(serverPipeline.targets).map((classId, order) => ({
      classId,
      order,
      ...serverPipeline.targets[classId],
    })),
  };
};

export const getProcessorsValueFromServer = (
  post_processor: FixFormDataEnhancementServer,
  targetsObj: Record<string, Document>
): FixFormDataEnhancementValue[] => {
  switch (post_processor.action) {
    case STEP_TYPE_DATA_ENHANCEMENT.FIND_AND_REPLACE: {
      return [post_processor.find || "", post_processor.replace || ""];
    }
    case STEP_TYPE_DATA_ENHANCEMENT.FORMAT_TEXT: {
      return (post_processor?.template || []).reduce((result: FixFormDataEnhancementValue[], item) => {
        if (!item) {
          return result;
        }

        if (item.type === FORMAT_TEXT_TYPE_DATA_ENHANCEMENT.LITERAL) {
          return [...result, item.value];
        }

        return [...result, { value: item.value, label: targetsObj[item.value]?.display_name }];
      }, []);
    }

    default:
      return [post_processor.remove_all ? "true" : "false"];
  }
};

export const normalizePipelineVersion = (pipelineVersion: PipelineVersionServer): PipelineVersion => {
  const targets = keys(pipelineVersion.targets).map((classId, order) => ({
    classId,
    order,
    ...pipelineVersion.targets[classId],
  }));
  const targetsObj = targets.reduce((result: Record<string, Document>, item: Document) => {
    return { ...result, [item.id || ""]: item };
  }, {});

  return {
    ...pipelineVersion,
    // isSelected: false,
    targets,
    post_processors: (pipelineVersion.post_processors || []).map(
      (item): IFixFormDataEnhancement => ({
        // ...item,
        id: item.id || makeIdTemp(),
        action: item.action || null,
        input: item.input.reduce((result: IFieldInfo[], id) => {
          if (targetsObj[id]) {
            return [...result, { id, display_name: targetsObj[id].display_name }];
          }
          return result;
        }, []),
        output: targetsObj[item.output]
          ? [{ id: item.output, display_name: targetsObj[item.output].display_name }]
          : [],
        value: getProcessorsValueFromServer(item, targetsObj),
        description: item.description || "", // will be updated by BE
      })
    ),
  };
};

export function getPipelines(params: APIQuery): Promise<APIResponse<Pipeline>> {
  return request({
    url: API_PATH.PIPELINES,
    method: "get",
    params,
  }).then((result) => {
    return {
      ...result,
      results: result.results.map((serverPipeline: ServerPipeline) => {
        return normalizePipeline(serverPipeline);
      }),
    };
  });
}

export async function getAllPipeline(params: APIQuery): Promise<APIResponse<Pipeline>> {
  const _params = { ...params };
  let offset = PIPELINES_OFFSET_DEFAULT;
  const limit = PIPELINES_LIMIT_DEFAULT;
  try {
    const result = await getPipelines({ ..._params, offset, limit });
    let _result = result;

    while (_result.next) {
      offset += limit;
      _result = await getPipelines({ ..._params, offset, limit });
      result.results = [...result.results, ..._result.results];
    }

    return {
      ...result,
    };
  } catch (error) {
    console.log("🚀 ~ file: pipeline.ts ~ line 139 ~ getAllPipeline ~ error", error);
    return Promise.reject(error);
  }
}

export const getPipelineDetail = async (id: PipelineId): Promise<Pipeline> => {
  const result = await request({
    url: `${API_PATH.PIPELINES}${id}/`,
    method: "get",
  });

  return normalizePipeline(result);
};

export const getPipelineVersionLatest = async (id: PipelineId): Promise<PipelineVersion> => {
  const result = await request({
    url: API_PATH.PIPELINES_VERSION_LATEST(id),
    method: "get",
  });

  return normalizePipelineVersion(result);
};

export const requestEditPipeline = async (id: PipelineId): Promise<PipelineVersion> => {
  const result = await request({
    url: API_PATH.PIPELINES_REQUEST_EDIT(id),
    method: "post",
  });

  return normalizePipelineVersion(result);
};

export const getFixedFormTemplateById = (
  pipeline__id?: string,
  options?: Record<string, string>
): Promise<APIResponse<Template>> => {
  const params = pipeline__id ? { pipeline__id } : {};
  return request({
    url: `${API_PATH.FIXED_TEMPLATES}`,
    method: "get",
    params: { ...params, ...(options || {}) },
  });
};

export const rerunFixedForm = (jobJd: string, new_fixed_template: string) => {
  return request({
    url: `${API_PATH.FIXED_FORM_RERUN(jobJd)}`,
    method: "post",
    data: { new_fixed_template },
  });
};

export const createNewPipeline = (data: Partial<Pipeline>): Promise<Pipeline> => {
  return request({
    url: API_PATH.PIPELINES,
    method: "post",
    data,
  });
};

export const deletePipeline = (id: string) => {
  return request({
    url: `${API_PATH.PIPELINES}${id}/`,
    method: "delete",
  });
};

export interface UpdatePipelinePayload {
  id: string;
  data: Partial<Pipeline>;
}

export const updatePipeline = (payload: UpdatePipelinePayload): Promise<Pipeline> => {
  const { id, data } = payload;

  return request({
    url: `${API_PATH.PIPELINES}${id}/`,
    method: "patch",
    data,
  });
};

export enum TargetAction {
  ADD = "add",
  EDIT = "edit",
  DELETE = "delete",
}

export interface TargetPayload extends Partial<Document> {
  code_name?: string;
  alternative_name?: string[];
}

export interface UpdateTargetPayload {
  action: TargetAction;
  id?: TargetId;
  payload?: TargetPayload;
}

export interface UpdateTargetsData {
  pipelineId: PipelineId;
  targets: UpdateTargetPayload[];
}

export const updateTargets = (payload: UpdateTargetsData) => {
  const { pipelineId, targets } = payload;

  return request({
    url: `${API_PATH.PIPELINES}${pipelineId}/targets/`,
    method: "post",
    data: { targets },
  });
};

interface IPayloadPostProcessorsPipeline {
  data: IFixFormDataEnhancement[];
}
const formatPayloadPipelineProcessor = (payload: IPayloadPostProcessorsPipeline): FixFormDataEnhancementServer[] => {
  const { data } = payload;
  return data.map((config): FixFormDataEnhancementServer => {
    let value = {};
    switch (config.action) {
      case STEP_TYPE_DATA_ENHANCEMENT.FIND_AND_REPLACE: {
        value = {
          find: config.value[0],
          replace: config.value[1],
        };
        break;
      }
      case STEP_TYPE_DATA_ENHANCEMENT.FORMAT_TEXT: {
        const { value: rawValues } = config;

        value = {
          template: rawValues.map((rawValue) => {
            if (typeof rawValue === "string") {
              return { type: FORMAT_TEXT_TYPE_DATA_ENHANCEMENT.LITERAL, value: rawValue };
            } else {
              return {
                type: FORMAT_TEXT_TYPE_DATA_ENHANCEMENT.PLACEHOLDER,
                value: rawValue.value,
              };
            }
          }),
        };
        break;
      }

      default: {
        value = {
          remove_all: config.value?.[0] === "true" ? true : false,
        };
      }
    }

    return {
      action: config.action,
      input: config.input.map((item) => item.id),
      output: config.output[0].id,
      description: config.description,
      ...value,
    };
  });
};
export const handlePostProcessorsPipeline = (id: PipelineId, steps: FixFormDataEnhancementServer[]) => {
  return request({
    url: `${API_PATH.PIPELINE_POST_PROCESSORS(id)}`,
    method: "PUT",
    data: {
      steps,
    },
  });
};

interface IPayloadPublishDiscardPipeline {
  id: PipelineId;
}
const onPublishPipeline = ({ id }: IPayloadPublishDiscardPipeline) => {
  return request({
    url: `${API_PATH.PIPELINES_PUBLISH(id)}`,
    method: "POST",
  });
};

const onDiscardPipeline = ({ id }: IPayloadPublishDiscardPipeline) => {
  return request({
    url: `${API_PATH.PIPELINES_DISCARD(id)}`,
    method: "POST",
  });
};

export interface IUpdatePipelineOptionsData {
  enable_single_field_advanced_options?: boolean;
  enable_single_field_extraction?: boolean;
  enable_table_field_advanced_options?: boolean;
  enable_table_field_extraction?: boolean;

  llm_model_type?: LLM_AI_SETTING_MODELS | null;
}
interface IUpdatePipelineOptions {
  id: PipelineId;

  data: IUpdatePipelineOptionsData;
}
const updatePipelineOptions = ({ id, data }: IUpdatePipelineOptions) => {
  return request({
    url: `${API_PATH.PIPELINES_OPTIONS(id)}`,
    method: "PATCH",
    data,
  });
};

export const pipelineApi = createApi({
  reducerPath: "pipelineApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Pipelines", "pipelineDetail", "PipelineVersions"],
  endpoints: (build) => ({
    getPipelines: build.query({
      queryFn: async (params: APIQuery) => {
        try {
          const data = await getPipelines(params);
          console.log("🚀 ~ queryFn getPipelines: ~ data:", data);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Pipelines"],
    }),
    getPipelineDetail: build.query({
      queryFn: async (id: PipelineId) => {
        try {
          const data = await getPipelineDetail(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["pipelineDetail"],
    }),
    getPipelineVersionLatest: build.query({
      queryFn: async (id: PipelineId) => {
        try {
          const data = await getPipelineVersionLatest(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["PipelineVersions"],
    }),
    requestEditPipeline: build.mutation({
      queryFn: async (id: PipelineId) => {
        try {
          const data = await requestEditPipeline(id);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    handlePostProcessorsPipeline: build.mutation({
      queryFn: async (_payload: { id: PipelineId; data: IFixFormDataEnhancement[] }) => {
        try {
          const { id, data } = _payload;
          const payloadFormatted = formatPayloadPipelineProcessor({ data });
          const result = await handlePostProcessorsPipeline(id, payloadFormatted);
          return { data: result };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["PipelineVersions", "pipelineDetail"],
    }),
    createNewPipeline: build.mutation({
      queryFn: async (payload: Partial<Pipeline>) => {
        try {
          const data = await createNewPipeline(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: rtkInvalidateOn({ success: ["Pipelines"], error: [] }),
    }),
    deletePipeline: build.mutation({
      queryFn: async (pipelineId: string) => {
        try {
          const data = await deletePipeline(pipelineId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Pipelines"],
    }),
    updatePipeline: build.mutation({
      queryFn: async (payload: UpdatePipelinePayload) => {
        try {
          const data = await updatePipeline(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["pipelineDetail", "PipelineVersions", "Pipelines"],
    }),
    updateTargets: build.mutation({
      queryFn: async (payload: UpdateTargetsData) => {
        try {
          const data = await updateTargets(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["pipelineDetail", "PipelineVersions"],
    }),
    publishPipeline: build.mutation({
      queryFn: async (payload: IPayloadPublishDiscardPipeline) => {
        try {
          const data = await onPublishPipeline(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["pipelineDetail", "PipelineVersions", "Pipelines"],
    }),
    discardPipeline: build.mutation({
      queryFn: async (payload: IPayloadPublishDiscardPipeline) => {
        try {
          const data = await onDiscardPipeline(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["pipelineDetail", "PipelineVersions", "Pipelines"],
    }),
    updatePipelineOptions: build.mutation({
      queryFn: async (payload: IUpdatePipelineOptions) => {
        try {
          const data = await updatePipelineOptions(payload);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["pipelineDetail", "PipelineVersions", "Pipelines"],
    }),
  }),
});

export const {
  useGetPipelinesQuery,
  useGetPipelineDetailQuery,
  useGetPipelineVersionLatestQuery,
  useRequestEditPipelineMutation,
  useHandlePostProcessorsPipelineMutation,
  useCreateNewPipelineMutation,
  useDeletePipelineMutation,
  useUpdatePipelineMutation,
  useUpdateTargetsMutation,
  usePublishPipelineMutation,
  useDiscardPipelineMutation,
  useUpdatePipelineOptionsMutation,
} = pipelineApi;
