import { ROUTE } from "@/constants/route";
import { useHasPermissions } from "@/hooks";
import { USER_PERMISSION } from "@/types/global";
import { Navigate } from "@cinnamon/design-system";
import { FunctionComponent, memo, PropsWithChildren, ReactNode } from "react";

export interface AuthRouteProps {
  permissions?: USER_PERMISSION[];
  isNavigateNotFound?: boolean;
  element?: ReactNode;
}

const AuthRoute: FunctionComponent<PropsWithChildren<AuthRouteProps>> = (props) => {
  const { element, children, permissions, isNavigateNotFound } = props;
  const hasPermissions = useHasPermissions();

  if (isNavigateNotFound) {
    return <Navigate to={ROUTE.NOT_FOUND.to} />;
  }

  if (permissions && !hasPermissions(permissions)) {
    return <Navigate to={ROUTE.NO_PERMISSION.to} />;
  }

  return <>{element || children}</>;
};

export default memo(AuthRoute);
