import * as featureFlagService from "@/services/featureFlag";
import { FeatureFlag } from "@/types/featureFlag";
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";

export interface FeatureFlagState {
  entities: FeatureFlag[];
  loading: boolean;
}

const initialState: FeatureFlagState = {
  entities: [],
  loading: false,
};

export const fetchAllFeatureFlags = createAsyncThunk("featureFlags/fetchAllStatus", async () => {
  const response = await featureFlagService.fetchAll();
  return response;
});

export const featureFlagSlice: Slice<FeatureFlagState> = createSlice({
  name: "featureFlags",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllFeatureFlags.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllFeatureFlags.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllFeatureFlags.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default featureFlagSlice.reducer;
