import { LANGUAGE_CODE } from "@/constants/global";
import { ReactChild, ReactChildren, ReactNode } from "react";
import { Target } from "./pipeline";
import { PostProcessors } from "./workflow";

export type QueryOperator = "$eq" | "$like" | "$in";
export type Sorting = "asc" | "desc";

export type DefaultLanguageChangeType = "browser" | "server";

export interface ILanguageChange {
  lang: keyof typeof LANGUAGE_CODE;
  defaultLangType?: DefaultLanguageChangeType;
}

export interface LocalStorageType {
  access_token: string;
  language?: "EN" | "JP";
}

// Use type string for unknown language code
export type LanguageCode = "en" | "ja" | string;

export interface APIQuery {
  total?: number;
  limit?: number;
  sort?: string;
  skip?: number;
  page?: number;
  filter?: Record<string, unknown>;
  search?: string;
  term?: string;
  max_results?: number;
  offset?: number;
  type?: string;
  ordering?: string;
  exec_type?: string;
  form_type?: string;
  build_type?: string;
  code_name?: string;
  status?: string;
  id?: string | number;
  with_deleted?: boolean;
}

export interface APIResponse<T> {
  count: number;
  previous: string;
  next: string;
  results: T[];
}

export interface ILinecutAnnotationPayLoad {
  height: number | null;
  left: number | null;
  page_idx: number | null;
  top: number | null;
  value: string | null;
  width: number | null;
  line_idx?: number | null;
}

export interface ChildrenType {
  children: ReactChild | ReactChildren;
}

export interface SelectOption {
  label: string;
  value: string | number;
  name?: string;
  display_name?: string;
}

export enum USER_PERMISSION {
  VIEW_USER = "view_user",
  ADD_USER = "add_user",
  CHANGE_USER = "change_user",
  DELETE_USER = "delete_user",

  VIEW_TEAM = "view_team",
  ADD_TEAM = "add_team",
  CHANGE_TEAM = "change_team",

  VIEW_PIPELINE = "view_pipeline",
  CHANGE_PIPELINE = "change_pipeline",
  ADD_PIPELINE = "add_pipeline",
  DELETE_PIPELINE = "delete_pipeline",

  VIEW_SYSTEM_SETTINGS = "view_systemsettings",
  CHANGE_SYSTEM_SETTINGS = "change_systemsettings",

  VIEW_PIPELINE_STATISTIC = "view_pipelinestatistic",

  VIEW_WORKFLOW = "view_workflow",
  ADD_WORKFLOW = "add_workflow",
  CHANGE_WORKFLOW = "change_workflow",
  DELETE_WORKFLOW = "delete_workflow",

  VIEW_JOB = "view_job",
  CHANGE_JOB = "change_job",

  RERUN_ERROR_JOB = "change_jobresultlock",
  RETRY_JOB = "retry_job",
}

export interface RouteSchema {
  to: string;
  path: string;
  icon?: ReactNode;
  text?: string;
  search?: string;
  permissions?: USER_PERMISSION[];
}

export interface WorkflowType {
  PageTitle: string;
  Header: string;
  WorkFlowType: string;
  SelectPipelineBodyTitle: string;
  StepList: StepProp[];
}

export interface StepProp {
  step: number;
  isActive: boolean;
  title: any;
  description?: any;
  id?: string;
  part?: string;
}

export interface WorkflowAPIQuery {
  id?: string;
  description?: string;
  display_name?: string;
  pipeline?: string;
  targets?: Record<string, Target>;
  pre_processors?: object;
  post_processors?: PostProcessors | null;

  executor?: {
    pipeline?: string;
    targets?: Record<string, Target>;
    pre_processors?: object;
    post_processors?: PostProcessors | null;
  };

  is_public?: boolean;
  users?: string[] | number[];
  teams?: string[] | number[];
}

export enum WorkFlowType {
  OCR = "OCR",
  CLASSIFICATION = "CLASSIFICATION",
}

export const defaultCallback = () => {
  // do nothing
};

export enum AI_PIPELINE_TYPE {
  DATA_EXTRACTION = "DATA_EXTRACTION",
  CLASSIFICATION = "CLASSIFICATION",
}

export enum FIX_FORM_CONFIG_TYPE {
  FIELDS = "FIELDS",
  TEMPLATES = "TEMPLATES",
  DATA_ENHANCEMENT = "DATA_ENHANCEMENT",
  RELEASES = "RELEASES",
}

export enum LLM_CONFIG_TYPE {
  AI_SETTING_MODEL = "AI_SETTING_MODEL",
  SINGLE_FIELDS = "SINGLE_FIELDS",
  TABLE_FIELDS = "TABLE_FIELDS",
  SAMPLES = "SAMPLES",
  DATA_ENHANCEMENT = "LLM_DATA_ENHANCEMENT",
  RELEASES = "LLM_RELEASES",
}

export enum CLASSIFICATION_GROUP_BY {
  FILE = "file",
  PAGE = "page",
  DOCUMENT_ID = "document_id",
}

export enum EXEC_TYPE {
  OCR = "ocr",
  CLASSIFICATION = "classification",
}

export enum BUILD_TYPE {
  PREBUILT = "prebuilt",
  VENDOR_CUSTOMIZED = "vendor-customized",
  CLIENT_CUSTOMIZED = "client-customized",
}

export enum FORM_TYPE {
  FIXED_FORM = "fixed-form",
  LLM_FORM = "llm-form",
  FREE_FORM = "free-form",
  CUSTOMIZE = "vendor-customized",
}

export enum HTTP_STATUS_CODE {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export interface APIErrorResponse<T> {
  status: HTTP_STATUS_CODE;
  data: T;
}

export enum FROM_PAGE_MANAGEMENT {
  FILE_MANAGEMENT = "fileManagement",
  PIPELINE_MANAGEMENT = "pipelineManagement",
  WORKFLOW_MANAGEMENT = "workflowManagement",
}

export interface LocationState {
  [FROM_PAGE_MANAGEMENT.FILE_MANAGEMENT]: string;
  [FROM_PAGE_MANAGEMENT.PIPELINE_MANAGEMENT]: string;
  [FROM_PAGE_MANAGEMENT.WORKFLOW_MANAGEMENT]: string;
}

export interface FieldConfigLocation {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface LocationFieldKVConfig {
  page_idx: number | null;
  location: FieldConfigLocation | null;
}

export interface OriginalOCRItem {
  location: FieldConfigLocation;
  text: string;
}

export interface OriginalOCR {
  ocr: OriginalOCRItem[];
}

export interface DropdownOption<T> {
  value: T;
  label: string;
}

export interface APIError {
  code?: string;
  detail?: string;
  attr?: string;
}

export interface ErrorResponse {
  errors?: APIError[];
}
