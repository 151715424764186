import { AZURE_CONFIG } from "@/constants/config";
import { CinLoaderFull, useMsal } from "@cinnamon/design-system";
import { FunctionComponent, memo, useEffect } from "react";

const Unauthenticated: FunctionComponent = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect({ scopes: AZURE_CONFIG.scopes });
  }, [instance]);

  return <CinLoaderFull />;
};

export default memo(Unauthenticated);
