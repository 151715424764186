import { AzureLoginPayload, UpdatedUserInput } from "@/types/user";
import { payloadToFormData } from "@cinnamon/design-system";
import { API_PATH } from "@constants/config";
import { request } from "@helper/http";

interface LoginPayload {
  email: string;
  password: string;
}

interface RefreshPayload {
  refresh: string;
}

export function refreshToken(payload: RefreshPayload) {
  return request({
    method: "POST",
    url: API_PATH.REFRESH,
    data: payloadToFormData(payload),
  });
}

export function getUserInfo(token: string) {
  return request({
    method: "GET",
    url: API_PATH.USER_INFO,
    headers: { Authorization: token },
  });
}

export function updateUserInfo(data: UpdatedUserInput) {
  return request({
    method: "patch",
    url: API_PATH.USER_INFO,
    data,
  });
}

export function login(payload: LoginPayload) {
  return request({
    method: "POST",
    url: API_PATH.LOGIN,
    data: payload,
  });
}

export interface ForgetPasswordPayload {
  email: string;
}

export const forgetPassword = (data: ForgetPasswordPayload) => {
  return request({
    url: API_PATH.FORGET_PASSWORD,
    method: "POST",
    data,
  });
};

export interface ResetPasswordPayload {
  token: string;
  password: string;
}

export const resetPassword = (payload: ResetPasswordPayload) => {
  const { token, password } = payload;

  return request({
    url: API_PATH.RESET_PASSWORD,
    method: "POST",
    params: { token },
    data: { password },
  });
};

export const checkResetPasswordToken = (token: string) => {
  return request({
    url: API_PATH.RESET_PASSWORD,
    method: "GET",
    params: { token },
  });
};

export const azureLogin = (payload: AzureLoginPayload) => {
  return request({
    method: "POST",
    url: API_PATH.AZURE_LOGIN,
    data: payload,
  });
};
