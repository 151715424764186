import { DATE_FORMAT, LANGUAGE_CODE } from "./global";

export const APP_CONFIG = {
  NAME: import.meta.env.VITE_NAME,
  LS_AUTH_DATA: `${import.meta.env.VITE_NAME}_auth_data`,
  LS_LANGUAGE: `language`,
  API_ROOT: `${window.API_ROOT || import.meta.env.VITE_API_DOMAIN}/api/v1`,
  AUTH_API_ROOT: `${window.API_ROOT || import.meta.env.VITE_API_DOMAIN}/api/auth`,
  DEFAULT_LANGUAGE: LANGUAGE_CODE.JAPANESE,
  UPLOAD_FILE_SUPPORT: [".pdf"],
  UPLOAD_QUEUE_LENGTH: 3,
  UPLOAD_MAX_SIZE: 52428800, // 50 MB
  DEFAULT_DOWNLOAD_FILE_NAME: "download",
  DEFAULT_DOWNLOAD_FILES_NAME: "downloads",
  DATE_FORMAT: DATE_FORMAT.YYYY_MM_DD_HH_MM_SLASH,
  USER: {
    PASSWORD_MIN_LENGTH: 5,
  },
  APP_ENV: window.APP_ENV || import.meta.env.VITE_APP_ENV,
};

export const AZURE_CONFIG = {
  msalConfig: {
    auth: {
      clientId: window.AZURE_CLIENT_ID || import.meta.env.VITE_AZURE_CLIENT_ID || "",
      authority: `https://login.microsoftonline.com/${window.AZURE_TENANT_ID || import.meta.env.VITE_AZURE_TENANT_ID}`,
      redirectUri: window.AZURE_ROOT_REDIRECT_URI || import.meta.env.VITE_AZURE_ROOT_REDIRECT_URI || "",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  scopes: [window.AZURE_SCOPE || import.meta.env.VITE_AZURE_SCOPE || ""],
};

export const API_PATH = {
  LOGIN: `${APP_CONFIG.API_ROOT}/auth/access/`,
  AZURE_LOGIN: `${APP_CONFIG.API_ROOT}/auth/social/azure/`,
  REFRESH: `${APP_CONFIG.API_ROOT}/auth/refresh/`,
  USER_INFO: `${APP_CONFIG.API_ROOT}/user/me/`,
  WORKFLOWS_LIST: `${APP_CONFIG.API_ROOT}/workflows`,
  WORKFLOW: (id: string) => `${APP_CONFIG.API_ROOT}/workflows/${id}/`,
  JOBS: `${APP_CONFIG.API_ROOT}/jobs/`,
  BULK_DELETE_JOB: `${APP_CONFIG.API_ROOT}/jobs/bulk/`,
  JOB_DOWNLOAD: (id: string) => `${APP_CONFIG.API_ROOT}/jobs/${id}/result/download/`,
  JOBS_DOWNLOAD_BATCH: `${APP_CONFIG.API_ROOT}/jobs/result/bulk-download/`,
  LOCK_JOB: (id: string) => `${APP_CONFIG.API_ROOT}/jobs/${id}/lock/`,
  BULK_CONFIRM_JOB: `${APP_CONFIG.API_ROOT}/jobs/result/bulk-confirm/`,
  USERS: `${APP_CONFIG.API_ROOT}/users/`,
  BULK_DELETE_USER: `${APP_CONFIG.API_ROOT}/users/bulk/`,
  CHANGE_PASSWORD: `${APP_CONFIG.AUTH_API_ROOT}/me/change-password/`,
  JOB_DATA: (id: string, showResult = true) => `${APP_CONFIG.API_ROOT}/jobs/${id}/?show_result=${showResult}`,
  STATIC: (jobId: string, category: string, fileIndex = 0) =>
    `${APP_CONFIG.API_ROOT}/static/resources/${jobId}/${category}/${fileIndex}`,
  FILES_CONTENT: (fileId: string) => `${APP_CONFIG.API_ROOT}/files/${fileId}/content`,
  PIPELINES: `${APP_CONFIG.API_ROOT}/pipelines/`,
  PIPELINES_VERSION_LATEST: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/versions/latest/`,
  PIPELINES_REQUEST_EDIT: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/request-edit/`,
  PIPELINES_PUBLISH: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/publish/`,
  PIPELINES_DISCARD: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/discard/`,
  PIPELINES_OPTIONS: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/options/`,
  PIPELINE_POST_PROCESSORS: (id: string) => `${APP_CONFIG.API_ROOT}/pipelines/${id}/post-processors/`,
  TEMPLATES: `${APP_CONFIG.API_ROOT}/templates/`,
  LLM_SAMPLES: `${APP_CONFIG.API_ROOT}/llm-samples/`,
  FIXED_TEMPLATES: `${APP_CONFIG.API_ROOT}/fixed-templates/`,
  FIXED_FORM_RERUN: (id: string) => `${APP_CONFIG.API_ROOT}/jobs/${id}/fixed-form/rerun/`,
  TEAMS: `${APP_CONFIG.API_ROOT}/departments/`,
  BULK_DELETE_TEAM: `${APP_CONFIG.API_ROOT}/teams/bulk/`,
  CHECK_EMAIL: `${APP_CONFIG.API_ROOT}/users/check-mail`,
  GROUPS: `${APP_CONFIG.API_ROOT}/groups/`,
  STATS: `${APP_CONFIG.API_ROOT}/stats`,
  SYSTEM_SETTINGS: `${APP_CONFIG.API_ROOT}/system-settings/`,
  RERUN_MULTIPLE_ERROR_JOBS: `${APP_CONFIG.API_ROOT}/jobs/retry/`,
  FORGET_PASSWORD: `${APP_CONFIG.AUTH_API_ROOT}/forgot-password/`,
  RESET_PASSWORD: `${APP_CONFIG.AUTH_API_ROOT}/reset-password/`,
  DICTIONARIES: `${APP_CONFIG.API_ROOT}/dictionaries/`,
  DEPARTMENTS: `${APP_CONFIG.API_ROOT}/departments/`,
  FEATURE_FLAG: `https://fehub.cinnamon.is/feature-flags/api/fsh/FSH2406/${APP_CONFIG.APP_ENV}`,
};
