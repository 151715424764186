import { DELIVERY_FORM_KEY, JOB_STATUS, MODEL_TYPE, ORDER_FORM_KEY } from "@/types/file";
import { CLASSIFICATION_GROUP_BY, FORM_TYPE, LanguageCode, QueryOperator } from "@/types/global";
import { PIPELINE_FIELD_TYPES } from "@/types/pipeline";
import { CinImageLabellingMode } from "@cinnamon/design-system";

export const LANGUAGE_CODE: Record<string, LanguageCode> = {
  ENGLISH: "en",
  JAPANESE: "ja",
  DEFAULT: "default",
};

export const LANGUAGE_TEXT = {
  [LANGUAGE_CODE.ENGLISH]: "English",
  [LANGUAGE_CODE.JAPANESE]: "日本語",
};

export const DATE_FORMAT = {
  YYYY_MMM: "YYYY MMM", // 2019 Oct
  MMM_DD_YYYY: "MMM, DD YYYY", // Oct, 05 2019
  YYYY_MM_DD_DOT: "YYYY.MM.DD", // 2019.10.05
  YYYY_MM_DD_HH_MM_DOT: "YYYY.MM.DD hh:mm", // 2019.10.05 17:43
  YYYY_MM_DD_HH_MM_SS_DOT: "YYYY.MM.DD hh:mm:ss", // 2019.10.05 17:43:30
  YYYY_MM_DD_SLASH: "YYYY/MM/DD", // 2019/10/05
  YYYY_MM_DD_HH_MM_SLASH: "YYYY/MM/DD hh:mm", // 2019/10/05 17:43
  YYYY_MM_DD_HH_MM_SS_SLASH: "YYYY/MM/DD hh:mm:ss", // 2019/10/05 17:43:30
  DD_MM_YYYY_SLASH: "DD/MM/YYYY", //24/03/2022,
  YYYYMMDDhhmmss: "YYYYMMDDhhmmss",
};

export const WEEKDAY_SHORT = {
  1: "DATE.MONDAY_ALT",
  2: "DATE.TUESDAY_ALT",
  3: "DATE.WEDNESDAY_ALT",
  4: "DATE.THURSDAY_ALT",
  5: "DATE.FRIDAY_ALT",
  6: "DATE.SATURDAY_ALT",
  7: "DATE.SUNDAY_ALT",
};

export const MONTH_SHORT: Record<number, string> = {
  1: "DATE.JANUARY_ALT",
  2: "DATE.FEBRUARY_ALT",
  3: "DATE.MARCH_ALT",
  4: "DATE.APRIL_ALT",
  5: "DATE.MAY_ALT",
  6: "DATE.JUNE_ALT",
  7: "DATE.JULY_ALT",
  8: "DATE.AUGUST_ALT",
  9: "DATE.SEPTEMBER_ALT",
  10: "DATE.OCTOBER_ALT",
  11: "DATE.NOVEMBER_ALT",
  12: "DATE.DECEMBER_ALT",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
};

export const HTTP_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const KEY_CODE = {
  ENTER: 13,
  SPACE: 32,
  Y: 89,
  Z: 90,
};

export const KEYBOARD_KEY = {
  ENTER: "Enter",
  SPACE: "Space",
  Y: "KeyY",
  Z: "KeyZ",
};

export const UPLOAD_STATUS: Record<Uppercase<string>, string> = {
  FAILED: "failed",
  PENDING: "pending",
  UPLOADING: "uploading",
  SUCCESS: "success",
};

export const UPLOAD_STATUS_TEXT = {
  [UPLOAD_STATUS.FAILED]: "COMMON.UPLOAD_STATUS_FAILED",
  [UPLOAD_STATUS.PENDING]: "COMMON.UPLOAD_STATUS_PENDING",
  [UPLOAD_STATUS.UPLOADING]: "COMMON.UPLOAD_STATUS_UPLOADING",
  [UPLOAD_STATUS.SUCCESS]: "COMMON.UPLOAD_STATUS_SUCCESS",
};

export const QUERY_OPERATOR: Record<string, QueryOperator> = {
  EQUAL: "$eq",
  LIKE: "$like",
  IN: "$in",
};

export type GlobalEvent = "logout";

export const GLOBAL_EVENTS: Record<Uppercase<GlobalEvent>, GlobalEvent> = {
  LOGOUT: "logout",
};

export const TABLE_CELL_TYPE = {
  ACTION: "action",
  CHECKBOX: "checkbox",
  INDEX: "index",
  STATUS: "status",
  TEXT: "text",
};

export const REFRESH_TOKEN_STORAGE_NAME = "refresh_token";

export const TOKEN_STORAGE_NAME = "token";

export const NO_ACCOUNT_FOUND_SERVER_ERROR = "No active account found with the given credentials";

export const INCORRECT_USER_LOGIN = "COMMON.INCORRECT_LOGIN_INFO";

export const ABLE_TO_DELETE_STATUSES = [
  JOB_STATUS.PENDING,
  JOB_STATUS.NEED_TO_REVIEW,
  JOB_STATUS.AWAITING_APPROVAL,
  JOB_STATUS.DONE,
  JOB_STATUS.ERROR,
];

export const ABLE_TO_DOWNLOAD_STATUSES = [JOB_STATUS.NEED_TO_REVIEW, JOB_STATUS.AWAITING_APPROVAL, JOB_STATUS.DONE];

export const ABLE_TO_RERUN_STATUSES = [JOB_STATUS.ERROR];

export const ABLE_TO_CONFIRM_STATUSES = [JOB_STATUS.NEED_TO_REVIEW];

export const ABLE_TO_OPEN_STATUSES = [
  JOB_STATUS.NEED_TO_REVIEW,
  JOB_STATUS.AWAITING_APPROVAL,
  JOB_STATUS.DONE,
  JOB_STATUS.ERROR,
];

export const ABLE_TO_LOCK_STATUSES = [JOB_STATUS.NEED_TO_REVIEW, JOB_STATUS.AWAITING_APPROVAL, JOB_STATUS.ERROR];

export const CLASSIFICATION_GROUP_BY_TEXT = {
  [CLASSIFICATION_GROUP_BY.FILE]: "COMMON.GROUP_BY_FILE",
  [CLASSIFICATION_GROUP_BY.PAGE]: "COMMON.GROUP_BY_PAGE",
  [CLASSIFICATION_GROUP_BY.DOCUMENT_ID]: "COMMON.GROUP_BY_DOCUMENT_ID",
};

export const FORM_TYPE_TEXT = {
  [FORM_TYPE.FREE_FORM]: "COMMON.FORM_TYPE_GENERAL",
  [FORM_TYPE.FIXED_FORM]: "COMMON.FORM_TYPE_FIXED_FORM",
  [FORM_TYPE.LLM_FORM]: "COMMON.FORM_TYPE_LLM_IE",
  [FORM_TYPE.CUSTOMIZE]: "COMMON.FORM_TYPE_CUSTOMIZED",
};

export const PIPELINE_FIELD_TYPES_TEXT: Record<string, string> = {
  [PIPELINE_FIELD_TYPES.TEXT]: "PAGES.FIX_FORM_CONFIG.TEXT",
  [PIPELINE_FIELD_TYPES.CHECKMARK]: "PAGES.FIX_FORM_CONFIG.CHECKMARK",
  [PIPELINE_FIELD_TYPES.GROUP_CHECKMARK]: "PAGES.FIX_FORM_CONFIG.GROUP_CHECKMARK",
  [PIPELINE_FIELD_TYPES.BLANK]: "PAGES.FIX_FORM_CONFIG.BLANK",
};

export const PIPELINE_FIELD_TYPES_ICON: Record<string, string> = {
  [PIPELINE_FIELD_TYPES.TEXT]: "text",
  [PIPELINE_FIELD_TYPES.CHECKMARK]: "done",
  [PIPELINE_FIELD_TYPES.GROUP_CHECKMARK]: "group-checkmark",
  [PIPELINE_FIELD_TYPES.BLANK]: "block",
};

export const LABELLING_MODE_TEXT: Record<string, string> = {
  [CinImageLabellingMode.Edit]: "COMMON.EDIT_LINECUT",
  [CinImageLabellingMode.DrawRectangle]: "COMMON.CREATE_LINECUT",
  [CinImageLabellingMode.Select]: "COMMON.SELECT_LINECUT",
};

export const EDITABLE_FORM_TYPES = [FORM_TYPE.FIXED_FORM, FORM_TYPE.LLM_FORM];

export const ORDER_FORM_KEY_TEXT = {
  [ORDER_FORM_KEY.DOCUMENT_TITLE]: "COMMON.DOCUMENT_TITLE",
  [ORDER_FORM_KEY.COMPANY_NAME]: "COMMON.COMPANY_NAME",
  [ORDER_FORM_KEY.COMPANY_DEPARTMENT_NAME]: "COMMON.COMPANY_DEPARTMENT_NAME",
  [ORDER_FORM_KEY.CONSUMER]: "COMMON.CONSUMER",
  [ORDER_FORM_KEY.DESTINATION_COMPANY_NAME]: "COMMON.DESTINATION_COMPANY_NAME",
  [ORDER_FORM_KEY.ORDER_DATE]: "COMMON.ORDER_DATE",
  [ORDER_FORM_KEY.TOTAL_AMOUNT]: "COMMON.TOTAL_AMOUNT",
  [ORDER_FORM_KEY.ORDER_NUMBER]: "COMMON.ORDER_NUMBER",
  [ORDER_FORM_KEY.PURCHASE_ORDER_NUMBER]: "COMMON.PURCHASE_ORDER_NUMBER",
  [ORDER_FORM_KEY.PROJECT_TITLE]: "COMMON.PROJECT_TITLE",
};

export const DELIVERY_FORM_KEY_TEXT = {
  [DELIVERY_FORM_KEY.DOCUMENT_TITLE]: "COMMON.DOCUMENT_TITLE",
  [DELIVERY_FORM_KEY.SUPPLIER_NAME]: "COMMON.SUPPLIER_NAME",
  [DELIVERY_FORM_KEY.PROJECT_TITLE]: "COMMON.PROJECT_TITLE",
  [DELIVERY_FORM_KEY.PURCHASE_ORDER_NUMBER]: "COMMON.PURCHASE_ORDER_NUMBER",
  [DELIVERY_FORM_KEY.DELIVERY_DESTINATION]: "COMMON.DELIVERY_DESTINATION",
  [DELIVERY_FORM_KEY.DELIVERY_DATE]: "COMMON.DELIVERY_DATE",
  [DELIVERY_FORM_KEY.CONTRACTOR_CODE]: "COMMON.CONTRACTOR_CODE",
};

export const MODEL_TYPE_TEXT = {
  [MODEL_TYPE.ORDER_FORM]: "COMMON.ORDER_FORM",
  [MODEL_TYPE.DELIVERY_FORM]: "COMMON.DELIVERY_FORM",
  [MODEL_TYPE.OTHERS]: "COMMON.OTHERS",
};

export const JOB_STATUS_TEXT = {
  [JOB_STATUS.PENDING]: "COMMON.STATUS_PENDING",
  [JOB_STATUS.PROCESSING]: "COMMON.STATUS_PROCESSING",
  [JOB_STATUS.NEED_TO_REVIEW]: "COMMON.STATUS_NEED_TO_REVIEW",
  [JOB_STATUS.AWAITING_APPROVAL]: "COMMON.STATUS_AWAITING_APPROVAL",
  [JOB_STATUS.DONE]: "COMMON.STATUS_DONE",
  [JOB_STATUS.EDITING]: "COMMON.STATUS_EDITING",
  [JOB_STATUS.ERROR]: "COMMON.STATUS_ERROR",
};
