import { departmentApi } from "@/services/department";
import { dictionaryApi } from "@/services/dictionary";
import { jobApi } from "@/services/file";
import { llmSampleApi } from "@/services/llmSample";
import { pipelineApi } from "@/services/pipeline";
import { statisticApi } from "@/services/statistic";
import { templateApi } from "@/services/template";
import { userApi } from "@/services/user";
import { workflowApi } from "@/services/workflow";
import { rootReducer } from "@redux/reducer/rootReducer";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: true,
    }).concat(
      workflowApi.middleware,
      jobApi.middleware,
      pipelineApi.middleware,
      templateApi.middleware,
      llmSampleApi.middleware,
      userApi.middleware,
      statisticApi.middleware,
      dictionaryApi.middleware,
      departmentApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const userState = (state: RootState) => state.user;
export const workflowState = (state: RootState) => state.workflow;
export const featureFlagState = (state: RootState) => state.featureFlag;

export default store;
