import { APP_CONFIG } from "@/constants/config";
import { apiService } from "@cinnamon/design-system";

// import { clearSession } from "@/redux/reducer/userSlice";
// import store from "@/redux/store";
import { clearLoginInfo } from "./utility";

apiService.setBaseUrlRequest(APP_CONFIG.API_ROOT);
// TODO JUN: need to refactor
// apiService.setClearSessionAction(() => store.dispatch(clearSession()));
apiService.setClearLoginInfo(clearLoginInfo);

export default apiService;
