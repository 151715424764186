import { API_PATH } from "@/constants/config";
import { request } from "@/helper/http";
import { rtkInvalidateOn } from "@/helper/rtkInvalidateOn";
import { CreateDepartmentPayload, Department, DepartmentId, UpdateDepartmentPayload } from "@/types/department";
import { APIQuery, APIResponse } from "@/types/global";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export interface DepartmentAPIQuery extends APIQuery {
  name__icontains?: string;
}

export const departmentApi = createApi({
  reducerPath: "departmentApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Departments"],
  endpoints: (build) => ({
    getDepartments: build.query({
      queryFn: async (params: DepartmentAPIQuery) => {
        try {
          const data: APIResponse<Department> = await request({
            url: API_PATH.DEPARTMENTS,
            method: "get",
            params,
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Departments"],
    }),
    getDepartment: build.query({
      queryFn: async (departmentId: DepartmentId) => {
        try {
          const data: Department = await request({
            url: `${API_PATH.DEPARTMENTS}${departmentId}/`,
            method: "get",
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Departments"],
    }),
    createDepartment: build.mutation({
      queryFn: async (payload: CreateDepartmentPayload) => {
        try {
          const data = await request({
            url: API_PATH.DEPARTMENTS,
            method: "post",
            data: payload,
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: rtkInvalidateOn({ success: ["Departments"], error: [] }),
    }),
    updateDepartment: build.mutation({
      queryFn: async (payload: UpdateDepartmentPayload) => {
        const { id, ...rest } = payload;

        try {
          const data = await request({
            url: `${API_PATH.DEPARTMENTS}${id}/`,
            method: "patch",
            data: rest,
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Departments"],
    }),
    deleteDepartment: build.mutation({
      queryFn: async (id: DepartmentId) => {
        try {
          const data = await request({
            url: `${API_PATH.DEPARTMENTS}${id}/`,
            method: "delete",
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Departments"],
    }),
    bulkDeleteDepartment: build.mutation({
      queryFn: async (ids: string) => {
        try {
          const data = await request({
            url: `${API_PATH.DEPARTMENTS}bulk/`,
            method: "delete",
            params: { ids },
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Departments"],
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useLazyGetDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useBulkDeleteDepartmentMutation,
} = departmentApi;
